import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {User} from '../model/user/user';

@Injectable({
    providedIn: 'root'
})
export class DashboardService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    get(type: string) {
        const url = `${this.BASE_URL}/dashboard/${type}`;
        return this.http.get(url);
    }

    getYears() {
        const url = `${this.BASE_URL}/dashboard/dashboard-years`;
        return this.http.get(url);
    }

}
