<div class="d-block d-xxl-flex">
    <div style="width: 100%;min-width: 60%" class="px-3">

        <div class="row mt-4 mb-3 px-3 px-md-0">
            <div class="col-12 col-md-2 px-0 px-xs-2 d-flex flex-nowrap">
                <a href="javascript:void('');"
                   (click)="step !== 1 ? handlerBack(step - 1) : router.navigate(['/my-list'])">
                    <svg-icon src="assets/svg/arrow.svg"></svg-icon>
                </a>
                <!--                   como era antes-->
                <!--                   *ngIf="modelRequest.step > 1 && (modelRequest.status !== 'approve' && (user.profile === 'ar_inter' || user.profile === 'administrator'))"-->
                <!--                        com regra de perfil-->
                <!--                   *ngIf="modelRequest.step > 1 && (modelRequest.status !== 'approve' && ((user.profile === 'commercial' || user.profile === 'administrator') && user.id === modelRequest.createdBy.id))">-->
                <a href="javascript: $('#modalBackStep').modal('show'); void('');" class="ml-4"
                   *ngIf="(modelRequest.step > 1 && (modelRequest.status !== 'approve' && (user.profile === 'commercial' || user.profile === 'administrator'))) && modelRequest.status !== 'archived'">
                    <svg-icon class="svg size-md" src="assets/svg/repair.svg"></svg-icon>
                </a>
                <a href="javascript: $('#modalHistoryDetail').modal('show'); void('');" class="ml-4"
                   *ngIf="modelRequest.historyAudits?.length > 0 && (modelRequest.status !== 'approve')">
                    <svg-icon class="svg size-md" src="assets/svg/files.svg"></svg-icon>
                </a>
            </div>
            <div class="col-7 col-lg-8 text-center">
                <span class="header-title" *ngIf="!isEdit">{{'novocadastro.title' | translate}}</span>
                <span class="header-title" *ngIf="isEdit">{{'novocadastro.titleDetail' | translate}}</span>
            </div>
            <div class="col-3 col-lg-2 px-0 px-xs-2">
                <span class="header-date">{{'novocadastro.criado' | translate}}
                    <strong *ngIf="!isEdit">{{createaAt | date: 'dd/MM/yyyy'}}</strong>
                    <strong *ngIf="isEdit">{{modelRequest.createdAt | date: 'dd/MM/yyyy'}}</strong></span>
            </div>
        </div>

        <div class="py-3 bg-beige rounded pb-5 px-1 px-xs-4 pt-4">

            <div class="nav_step shadow mx-auto mb-4"
                 [ngClass]="{'d-none': !(modelRequest.status == 'open'|| modelRequest.status == 'archived' || isNullOrUndefined(modelRequest.status))}">
                <ul class="nav d-flex justify-content-between" id="myTab" role="tablist">
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step1-tab" data-toggle="tab" href="#step1" role="tab"
                           aria-controls="step1" aria-selected="true">
                            <span class="step-legend">Comercial</span>
                            <span class="step">1</span>
                            <div class="position_item" *ngIf="!stepOneDisabled">
                                <span class="position_item-stage">{{'minhalista.input8.title' | translate}}</span>
                                <div class="question"><span
                                        class="position_item-name">{{'novocadastro.etapa1.title' | translate}}</span>
                                </div>
                            </div>

                            <div class="position_item"
                                 *ngIf="stepOneDisabled && !this.isNullOrUndefined(handlerDateTitle(1))">
                                <span class="position_item-name">
                                    <span class="font-weight-normal">{{handlerDateTitle(1) | date: 'dd/MM/yyyy'}}</span> <br>
                                    <div class="font-weight-600 text-truncate">{{handlerNameTitle(1)}}</div>
                                    {{'novocadastro.etapa1.title' | translate}}
                                </span>
                            </div>
                            <div class="finish_position_item">
                                <span class="position_item-stage">
                                    {{handlerDateTitle(1) | date: 'dd/MM/yyyy'}}
                                    <span class="d-block font-weight-600"> {{handlerNameTitle(1)}}</span>
                                    <!--<br>
                                    {{'novocadastro.etapa1.title' | translate}} -->
                                </span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step2-tab" data-toggle="tab" href="#step2" role="tab"
                           aria-controls="step2" aria-selected="false">
                            <span class="step-legend">AR Local</span>
                            <span class="step">2</span>
                            <div class="position_item" *ngIf="!stepTwoDisabled">
                                <span class="position_item-stage">Etapa</span>
                                <span class="position_item-name">AR Local</span>
                            </div>
                            <div class="position_item"
                                 *ngIf="stepTwoDisabled && !this.isNullOrUndefined(handlerDateTitle(2))">
                                <span class="position_item-name">
                                    <span class="font-weight-normal">{{handlerDateTitle(2) | date: 'dd/MM/yyyy'}}</span> <br>
                                    <span class="font-weight-600 text-truncate">{{handlerNameTitle(2)}}</span> <br>
                                    AR Local
                                </span>
                            </div>
                            <div class="finish_position_item">
                                <span class="position_item-stage">
                                    {{handlerDateTitle(2) | date: 'dd/MM/yyyy'}}
                                    <span class="d-block font-weight-600"> {{handlerNameTitle(2)}}</span>
                                </span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step3-tab" data-toggle="tab" href="#step3" role="tab"
                           aria-controls="step3" aria-selected="false">
                            <span class="step-legend">
                                <img class="step-legend-brand" src="assets/images/country/{{modelRequest.area_400 === 'PMOINT' ? modelRequest.countryManufacturingPmoInt + '.svg' : 'brazil.svg'}}" alt="" />
                                AR Internacional
                            </span>
                            <span class="step">3</span>
                            <div class="position_item" *ngIf="!stepThreeDisabled">
                                <span class="position_item-stage">Etapa</span>
                                <span class="position_item-name">AR Internacional</span>
                            </div>
                            <div class="position_item"
                                 *ngIf="stepThreeDisabled && !this.isNullOrUndefined(handlerDateTitle(3))">
                                <span class="position_item-name">
                                    <span class="font-weight-normal">{{handlerDateTitle(3) | date: 'dd/MM/yyyy'}}</span><br>
                                    <span class="font-weight-600 text-truncate">{{handlerNameTitle(3)}}</span> <br>
                                    AR Internacional
                                </span>
                            </div>
                            <div class="finish_position_item">
                                <span class="position_item-stage">
                                    {{handlerDateTitle(3)| date: 'dd/MM/yyyy'}}
                                    <span class="d-block font-weight-600"> {{handlerNameTitle(3)}}</span>
                                </span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step4-tab" data-toggle="tab" href="#step4" role="tab"
                           aria-controls="step4" aria-selected="false">
                            <span class="step-legend">
                                <img class="step-legend-brand" src="assets/images/country/brazil.svg" alt="" />
                                Artes
                            </span>
                            <span class="step">4</span>
                            <div class="position_item text-right" *ngIf="!stepFourDisabled">
                                <span class="position_item-stage">Etapa</span>
                                <span class="position_item-name">Artes</span>
                            </div>
                            <div class="position_item text-right"
                                 *ngIf="stepFourDisabled && !this.isNullOrUndefined(handlerDateTitle(4))">
                                <span class="position_item-name">
                                    <span class="font-weight-normal">{{handlerDateTitle(4) | date: 'dd/MM/yyyy'}}</span><br>
                                    <span class="font-weight-600 text-truncate">{{handlerNameTitle(4)}}</span> <br>
                                    Artes
                                </span>
                            </div>
                            <div class="finish_position_item">
                                <span class="position_item-stage">
                                    <span class="d-none"
                                          *ngIf="isNullOrUndefined(handlerDateTitle(4))">Não aplicável</span>
                                    <span *ngIf="!isNullOrUndefined(handlerDateTitle(4))">
                                        {{handlerDateTitle(4) | date: 'dd/MM/yyyy'}}
                                        <span class="d-block font-weight-600"> {{handlerNameTitle(4)}}</span>
                                    </span>
                                </span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step5-tab" data-toggle="tab" href="#step5" role="tab"
                           aria-controls="step5" aria-selected="false">
                            <span class="step-legend">
                                <img class="step-legend-brand" src="assets/images/country/{{modelRequest.area_400 === 'PMOINT' ? modelRequest.countryManufacturingPmoInt + '.svg' : 'brazil.svg'}}" alt="" />
                                GQ DOC
                            </span>
                            <span class="step">5</span>
                            <div class="position_item text-right" *ngIf="!stepFiveDisabled">
                                <span class="position_item-stage">Etapa</span>
                                <span class="position_item-name">Cadastro</span>
                            </div>
                            <div class="position_item text-right"
                                 *ngIf="stepFiveDisabled && !this.isNullOrUndefined(handlerDateTitle(5))">
                                <span class="position_item-name">
                                    <span class="font-weight-normal">{{handlerDateTitle(5) | date: 'dd/MM/yyyy'}}</span> <br>
                                    <span class="font-weight-600 text-truncate">{{handlerNameTitle(5)}}</span> <br>
                                    Cadastro
                                </span>
                            </div>
                            <div class="finish_position_item">
                                <span class="position_item-stage">
                                    {{handlerDateTitle(5) | date: 'dd/MM/yyyy'}}
                                    <span class="d-block font-weight-600"> {{handlerNameTitle(5)}}</span>
                                </span>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>


            <div class="nav_step shadow mx-auto mb-5"
                 [ngClass]="{'d-none': !(!isNullOrUndefined(modelRequest.status) && (modelRequest.status !== 'open' && modelRequest.status !== 'archived'))}">
                <ul class="nav d-flex justify-content-between" id="myTabF" role="tablist">
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step1-tabF" data-toggle="tab"
                           [ngClass]="{'active' : modelRequest.step === 1, 'finish' : modelRequest.step >= 1}"
                           href="#step1" role="tab" aria-controls="step1" aria-selected="true">
                            <span class="step-legend">Comercial</span>
                            <span class="step">1</span>
                            <div class="position_item">
                                <span class="position_item-stage">
                                    {{'novocadastro.etapa1.title' | translate}} <br>
                                    <span class="d-block font-weight-600">
                                        {{handlerDateTitle(1) | date: 'dd/MM/yyyy'}}
                                        <br>
                                        {{handlerNameTitle(1)}}
                                    </span>
                                </span>
                            </div>
                            <div class="finish_position_item">
                                <span class="position_item-stage">
                                    {{handlerDateTitle(1) | date: 'dd/MM/yyyy'}}
                                    <span class="d-block font-weight-600"> {{handlerNameTitle(1)}}</span>
                                </span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step2-tabF" data-toggle="tab"
                           [ngClass]="{'active' : modelRequest.step === 2, 'finish' : modelRequest.step >= 2}"
                           href="#step2" role="tab" aria-controls="step2" aria-selected="false">
                            <span class="step-legend">AR Local</span>
                            <span class="step">2</span>
                            <div class="position_item">
                                <span class="position_item-stage">
                                    AR Local <br>
                                    <span class="d-block font-weight-600">
                                        {{handlerDateTitle(2) | date: 'dd/MM/yyyy'}}
                                        <br>
                                        {{handlerNameTitle(2)}}
                                    </span>
                                </span>
                            </div>
                            <div class="finish_position_item">
                                <span class="position_item-stage">
                                    {{handlerDateTitle(2) | date: 'dd/MM/yyyy'}}
                                    <span class="d-block font-weight-600"> {{handlerNameTitle(2)}}</span>
                                </span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step3-tabF" data-toggle="tab"
                           [ngClass]="{'active' : modelRequest.step === 3, 'finish' : modelRequest.step >= 3}"
                           href="#step3" role="tab" aria-controls="step3" aria-selected="false">
                            <span class="step">3</span>
                            <div class="position_item">
                                <span class="position_item-stage">
                                    AR Internacional <br>
                                    <span class="d-block font-weight-600">
                                        {{handlerDateTitle(3) | date: 'dd/MM/yyyy'}}
                                        <br>
                                        {{handlerNameTitle(3)}}
                                    </span>
                                </span>
                            </div>
                            <div class="finish_position_item">
                                <span class="position_item-stage">
                                    {{handlerDateTitle(3) | date: 'dd/MM/yyyy'}}
                                    <span class="d-block font-weight-600"> {{handlerNameTitle(3)}}</span>
                                </span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step4-tabF" data-toggle="tab"
                           [ngClass]="{'active' : modelRequest.step === 4, 'finish' : modelRequest.step >= 4}"
                           href="#step4" role="tab" aria-controls="step4" aria-selected="false">
                            <span class="step">4</span>
                            <div class="position_item">
                                <span class="position_item-stage">
                                    Artes <br>
                                    <span *ngIf="isNullOrUndefined(handlerDateTitle(4))">Não aplicável</span>
                                    <span *ngIf="!isNullOrUndefined(handlerDateTitle(4))">
                                        <span class="d-block font-weight-600">
                                        {{handlerDateTitle(4) | date: 'dd/MM/yyyy'}}
                                            <br>
                                            {{handlerNameTitle(4)}}
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <div class="finish_position_item">
                                <span class="position_item-stage">
                                    {{handlerDateTitle(4) | date: 'dd/MM/yyyy'}}
                                    <span class="d-block font-weight-600"> {{handlerNameTitle(4)}}</span>
                                </span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step5-tabF" data-toggle="tab"
                           [ngClass]="{'active' : modelRequest.step === 5, 'finish' : modelRequest.step >= 5}"
                           href="#step5" role="tab" aria-controls="step5" aria-selected="false">
                            <span class="step">5</span>
                            <div class="position_item">
                                <span class="position_item-stage">
                                    Cadastro <br>
                                    <span class="d-block font-weight-600">
                                        {{handlerDateTitle(5) | date: 'dd/MM/yyyy'}}
                                        <br>
                                        {{handlerNameTitle(5)}}
                                    </span>
                                </span>
                            </div>
                            <div class="finish_position_item">
                                <span class="position_item-stage">
                                    {{handlerDateTitle(5) | date: 'dd/MM/yyyy'}}
                                    <span class="d-block font-weight-600"> {{handlerNameTitle(5)}}</span>
                                </span>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>

        </div>

        <div class="tab-content" id="myTabContent">
            <!--request-one-->
            <div class="tab-pane fade show active" id="step1" role="tabpanel" aria-labelledby="step1-tab">
                <div class="mx-auto p-4 tab-pane-content">
                    <div class="row mt-5">
                        <div class="col-12">
                            <p class="font-weight-bold primary">{{'step1.ola' | translate}} :)</p>
                            <p>{{'step1.p1' | translate}}
                            </p>
                            <br>
                            <p>{{'step1.p2' | translate}}</p>
                            <p>{{'ifyouhavedoubts'|translate}}</p>
                            <!--<div class="input-material">
                                <input class="form-control" id="name" maxlength="200" type="text" required
                                       [(ngModel)]="modelRequest.name" [disabled]="stepOneDisabled"/>
                                <label for="name"
                                       [ngClass]="{'disabled' : stepOneDisabled}">{{'general.nomeprojeto' | translate}}</label>
                                <div>
                                    <a href="javascript:void('')" class="d-block question_beside" data-toggle="popover"
                                       [attr.title]="getTranslateInstantKey('popovers.step1.pop1.title')"
                                       [attr.data-content]="getTranslateInstantKey('popovers.step1.pop1.text')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>-->


                            <div class="input-material">
                                <input class="form-control" id="code" maxlength="200" type="text" required
                                       appOnlyNumber
                                       data-toggle="tooltip"
                                       data-placement="top" title="{{'codigomodeloquetenha' | translate}}"
                                       [(ngModel)]="modelRequest.code" [disabled]="stepOneDisabled"/>
                                <label for="code"
                                       [ngClass]="{'disabled' : stepOneDisabled}">{{'general.codigomodelo' | translate}}
                                    *</label>
                                <div>
                                    <a href="javascript:void('')" class="d-block question_beside" data-toggle="popover"
                                       [attr.title]="getTranslateInstantKey('popovers.step1.pop2.title')"
                                       [attr.data-content]="getTranslateInstantKey('popovers.step1.pop2.text')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>
                            <div class="input-material">
                                <select id="reasonRequest" class="form-control" name="type"
                                        data-toggle="tooltip"
                                        data-placement="top" title="{{'lancamentodenovoproduto' | translate}}"
                                        [(ngModel)]="modelRequest.reasonRequest" [disabled]="stepOneDisabled">
                                    <option [ngValue]="null">{{'general.selecione' | translate}}</option>
                                    <option [ngValue]="0">{{'general.launch' | translate}}</option>
                                    <option [ngValue]="1">{{'general.newSendBulk' | translate}}</option>
                                    <!--<option *ngFor="let c of listChannel" [ngValue]="c.name">{{c.description}}</option>-->
                                </select>
                                <label [ngClass]="{'disabled' : stepOneDisabled}">{{'general.reasonRequest' | translate}}
                                    *</label>
                                <div>
                                    <a href="javascript:void('')" class="d-block question_beside" data-toggle="popover"
                                       [attr.title]="getTranslateInstantKey('popovers.step1.pop3.title')"
                                       [attr.data-content]="getTranslateInstantKey('popovers.step1.pop3.text')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>

                            <div class="col-12 my-4 p-0" *ngIf="false">
                                <div class="bg-beige rounded p-3">
                                    <div>
                                        <strong>{{'qualAreaRecebeCodigoCriado' | translate}}</strong>
                                    </div>
                                    <div class="input-material align-items-center d-flex">
                                        <label>&nbsp; <!--{{'general.concentration' | translate}} *--></label>
                                        <div class="w-100">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="radio" class="custom-control-input" name="step1area"
                                                       data-toggle="tooltip"
                                                       data-placement="top"
                                                       title="{{'pmoi'|translate}}"
                                                       (ngModelChange)="actionArea($event)"
                                                       [disabled]="stepOneDisabled"
                                                       [value]="'PMOI'"
                                                       [ngModel]="modelRequest.area_400"
                                                       [checked]="modelRequest.area_400 === 'PMOI'"/>
                                                <label class="custom-control-label">{{'pmoino'|translate}}</label>
                                            </div>
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="radio" class="custom-control-input" name="step1area"
                                                       data-toggle="tooltip"
                                                       data-placement="bottom"
                                                       title="{{'pmo'|translate}}"
                                                       (ngModelChange)="actionArea($event)"
                                                       [disabled]="stepOneDisabled"
                                                       [value]="'PMOINT'"
                                                       [ngModel]="modelRequest.area_400"
                                                       [checked]="modelRequest.area_400 === 'PMOINT'"/>
                                                <label class="custom-control-label">PMO OP INT</label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="col-12 my-4 p-0" *ngIf="modelRequest.area_400 === 'PMOINT'">
                                <h6 class="text-primary mt-4">País solicitante</h6>
                                <div class="bg-beige p-2 px-1 px-xs-5 rounded">
                                    <div class="assign-team my-4">
                                        <div>
                                            <a class="d-inline-flex align-items-center justify-content-center flex-column m-3"
                                               *ngFor="let n of modelDescriptionCountry"
                                               (click)="handlerSelectedCountryRequester(n.name, n.description)"
                                               [ngClass]="{'active' : n.description === modelRequest.countrySolicitationPmoInt}"
                                               href="javascript:void('');">
                                                <!--<svg-icon src="assets/images/country/{{n.thumb}}"></svg-icon>-->
                                                <img src="assets/images/country/{{n.thumb}}" alt="" />
                                                <span>{{n.name === 'CHI' ? 'CH' : n.name}}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <h6 class="text-primary mt-4">País fabricante</h6>
                                <div class="bg-beige p-2 px-1 px-xs-5 rounded">
                                    <div class="assign-team my-4">
                                        <div>
                                            <a class="d-inline-flex align-items-center justify-content-center flex-column m-3"
                                               (click)="handlerSelectedCountryManufacturing('BRA', 'brazil')"
                                               [ngClass]="{'active' : 'brazil' === modelRequest.countryManufacturingPmoInt}"
                                               href="javascript:void('');" *ngIf="'brazil' === modelRequest.countryManufacturingPmoInt">
                                                <svg-icon src="assets/images/country/brazil.svg"></svg-icon>
                                                <span>BRA</span>
                                            </a>
                                            <a class="d-inline-flex align-items-center justify-content-center flex-column m-3"
                                               *ngFor="let n of modelDescriptionCountry"
                                               (click)="handlerSelectedCountryManufacturing(n.name, n.description)"
                                               [ngClass]="{'active' : n.description === modelRequest.countryManufacturingPmoInt}"
                                               href="javascript:void('');">
                                                <!--<svg-icon src="assets/images/country/{{n.thumb}}"></svg-icon>-->
                                                <img src="assets/images/country/{{n.thumb}}" alt="" />
                                                <span>{{n.name === 'CHI' ? 'CH' : n.name}}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <hr />
                            </div>

                            <div class="input-material">
                                <input class="form-control" maxlength="200" type="text" required
                                       data-toggle="tooltip"
                                       data-placement="top" title="{{'escreveramolecula' | translate}}"
                                       [(ngModel)]="modelRequest.molecule"
                                       [disabled]="stepOneDisabled"/>
                                <label [ngClass]="{'disabled' : stepOneDisabled}">{{'general.molecule' | translate}}
                                    *</label>
                                <div>
                                    <a href="javascript:void('')" class="d-block question_beside" data-toggle="popover"
                                       [attr.title]="getTranslateInstantKey('popovers.step1.pop4.title')"
                                       [attr.data-content]="getTranslateInstantKey('popovers.step1.pop4.text')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>
                            <div class="input-material">
                                <input class="form-control" maxlength="200" type="text" required
                                       data-toggle="tooltip"
                                       data-placement="top" title="{{'escreverconcentracaocomercial' | translate}}"
                                       [(ngModel)]="modelRequest.concentration"
                                       [disabled]="stepOneDisabled"/>
                                <label [ngClass]="{'disabled' : stepOneDisabled}">{{'general.concentration' | translate}}
                                    *</label>
                                <div>
                                    <a href="javascript:void('')" class="d-block question_beside" data-toggle="popover"
                                       [attr.title]="getTranslateInstantKey('popovers.step1.pop5.title')"
                                       [attr.data-content]="getTranslateInstantKey('popovers.step1.pop5.text')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>
                            <div class="input-material align-items-center d-flex" data-toggle="tooltip"
                                 data-placement="top"
                                 title="{{'seoprodutonaoforgenericoporfavor'|translate}}">
                                <label>&nbsp; <!--{{'general.concentration' | translate}} *--></label>
                                <div class="w-100">
                                    <div class="custom-control custom-checkbox custom-control-inline">
                                        <input type="radio" class="custom-control-input" name="step1Radio"
                                               (ngModelChange)="actionItemConcentration($event)"
                                               [disabled]="stepOneDisabled"
                                               [value]="'BRAND'"
                                               [ngModel]="modelRequest.concentrationType"
                                               [checked]="modelRequest.concentrationType === 'BRAND'"
                                        />
                                        <label class="custom-control-label">{{'general.brand' | translate}}</label>
                                    </div>
                                    <div class="custom-control custom-checkbox custom-control-inline">
                                        <input type="radio" class="custom-control-input" name="step1Radio"
                                               (ngModelChange)="actionItemConcentration($event)"
                                               [disabled]="stepOneDisabled"
                                               [value]="'GENERIC'"
                                               [ngModel]="modelRequest.concentrationType"
                                               [checked]="modelRequest.concentrationType === 'GENERIC'"
                                        />
                                        <label class="custom-control-label">{{'general.generic' | translate}}</label>
                                    </div>
                                </div>
                                <div>
                                    <a href="javascript:void('')" class="d-block question_beside" data-toggle="popover"
                                       [attr.data-content]="getTranslateInstantKey('genericproduct')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>
                            <div class="input-material" *ngIf="modelRequest.concentrationType === 'BRAND'">
                                <input class="form-control" maxlength="200" type="text" required
                                       [(ngModel)]="modelRequest.productBrand"
                                       [disabled]="stepOneDisabled"/>
                                <label [ngClass]="{'disabled' : stepOneDisabled}">{{'general.productBrand' | translate}}
                                    *</label>
                            </div>
                            <div class="input-material align-items-center d-flex"
                                 data-toggle="tooltip"
                                 data-placement="top"
                                 title="{{'sehouverumasolicitacaodecodigopraprodutooriginal'|translate}}"
                                 *ngIf="modelRequest.reasonRequest !== 1">
                                <label>&nbsp; <!--{{'general.productBrand' | translate}} *--></label>
                                <div class="w-100">
                                    <div class="custom-control custom-checkbox custom-control-inline">
                                        <input type="radio" class="custom-control-input" name="step2Radio"
                                               (ngModelChange)="actionItemProductBrand($event)"
                                               [disabled]="stepOneDisabled"
                                               [value]="'original'"
                                               [ngModel]="modelRequest.productBrandType"
                                               [checked]="modelRequest.productBrandType === 'original'"
                                        />
                                        <label class="custom-control-label">{{'general.original' | translate}}</label>

                                    </div>
                                    <div class="custom-control custom-checkbox custom-control-inline">
                                        <input type="radio" class="custom-control-input" name="step2Radio"
                                               (ngModelChange)="actionItemProductBrand($event)"
                                               [disabled]="stepOneDisabled"
                                               [value]="'muestra-medica'"
                                               [ngModel]="modelRequest.productBrandType"
                                               [checked]="modelRequest.productBrandType === 'muestra-medica'"
                                        />
                                        <label class="custom-control-label">{{'general.medicalSample' | translate}}</label>
                                    </div>
                                </div>
                                <div>
                                    <a href="javascript:void('')" class="d-block question_beside" data-toggle="popover"
                                       [attr.data-content]="getTranslateInstantKey('itmusthave')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>
                            <div class="input-material" *ngIf="modelRequest.reasonRequest !== 1">
                                <input class="form-control" maxlength="200" type="text" required data-toggle="tooltip"
                                       data-placement="top"
                                       title="{{'informarapresentacao'|translate}}"
                                       [disabled]="stepOneDisabled"
                                       [(ngModel)]="modelRequest.commercialPresentation"
                                />
                                <label [ngClass]="{'disabled' : stepOneDisabled}">{{'general.commercialPresentation' | translate}}
                                    *</label>
                                <div>
                                    <a href="javascript:void('')" class="d-block question_beside" data-toggle="popover"
                                       [attr.data-content]="getTranslateInstantKey('informpresentation')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 mt-3" *ngIf="(stepOneDisabled && modelRequest.area_400 !== 'PMOINT') || (!stepOneDisabled && country === 'colombia')">

                            <div class="bg-beige rounded p-3">
                                <h6 class="text-primary">{{'general.labelCountry3' | translate}}</h6>
                                <p class="mb-2"
                                   *ngFor="let x of modelRequestResult.countryNotifications">{{getCountryNameByKey(x)}}</p>
                            </div>
                        </div>
                        <div class="col-12 mt-3" *ngIf="!stepOneDisabled && modelRequest.area_400 !== 'PMOINT'">
                            <div class="w-100">
                                <div class="bg-beige rounded p-3">
                                    <ng-container *ngIf="country == 'uruguai'">
                                        <h6 class="text-primary">{{'general.labelCountry1' | translate}}</h6>
                                        <div class="bg-beige py-3 rounded row mx-0 align-items-center mb-3">
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="uruguai"
                                                           (click)="handlerSelectedCountry('uruguai')"
                                                           [checked]="handlerCheckCountry('uruguai')"
                                                           [disabled]="handlerDisabledCountry('uruguai')">
                                                    <label class="custom-control-label"
                                                           for="uruguai">{{'country.uruguai' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="bolivia"
                                                           (click)="handlerSelectedCountry('bolivia')"
                                                           [checked]="handlerCheckCountry('bolivia')"
                                                           [disabled]="handlerDisabledCountry('bolivia')">
                                                    <label class="custom-control-label"
                                                           for="bolivia">{{'country.bolivia' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="paraguai"
                                                           (click)="handlerSelectedCountry('paraguai')"
                                                           [checked]="handlerCheckCountry('paraguai')"
                                                           [disabled]="handlerDisabledCountry('paraguai')">
                                                    <label class="custom-control-label"
                                                           for="paraguai">{{'country.paraguai' | translate}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="handlerCheckCountryVisible()">
                                        <h6 class="text-primary">{{'general.labelCountry1' | translate}}</h6>
                                        <div class="bg-beige py-3 rounded row mx-0 align-items-center mb-3">
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="argentina1"
                                                           (click)="handlerSelectedCountry('argentina')"
                                                           [checked]="handlerCheckCountry('argentina')"
                                                           [disabled]="handlerDisabledCountry('argentina')">
                                                    <label class="custom-control-label"
                                                           for="argentina1">{{'country.argentina' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="bolivia1"
                                                           (click)="handlerSelectedCountry('bolivia')"
                                                           [checked]="handlerCheckCountry('bolivia')"
                                                           [disabled]="handlerDisabledCountry('bolivia')">
                                                    <label class="custom-control-label"
                                                           for="bolivia1">{{'country.bolivia' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="brazil1"
                                                           (click)="handlerSelectedCountry('brazil')"
                                                           [checked]="handlerCheckCountry('brazil')"
                                                           [disabled]="handlerDisabledCountry('brazil')">
                                                    <label class="custom-control-label"
                                                           for="brazil1">{{'country.brazil' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="chile1"
                                                           (click)="handlerSelectedCountry('chile')"
                                                           [checked]="handlerCheckCountry('chile')"
                                                           [disabled]="handlerDisabledCountry('chile')">
                                                    <label class="custom-control-label"
                                                           for="chile1">{{'country.chile' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="colombia1"
                                                           (click)="handlerSelectedCountry('colombia')"
                                                           [checked]="handlerCheckCountry('colombia')"
                                                           [disabled]="handlerDisabledCountry('colombia')">
                                                    <label class="custom-control-label"
                                                           for="colombia1">{{'country.colombia' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="equador1"
                                                           (click)="handlerSelectedCountry('equador')"
                                                           [checked]="handlerCheckCountry('equador')"
                                                           [disabled]="handlerDisabledCountry('equador')">
                                                    <label class="custom-control-label"
                                                           for="equador1">{{'country.equador' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="mexico1"
                                                           (click)="handlerSelectedCountry('mexico')"
                                                           [checked]="handlerCheckCountry('mexico')"
                                                           [disabled]="handlerDisabledCountry('mexico')">
                                                    <label class="custom-control-label"
                                                           for="mexico1">{{'country.mexico' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="peru1"
                                                           (click)="handlerSelectedCountry('peru')"
                                                           [checked]="handlerCheckCountry('peru')"
                                                           [disabled]="handlerDisabledCountry('peru')">
                                                    <label class="custom-control-label"
                                                           for="peru1">{{'country.peru' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="paraguai1"
                                                           (click)="handlerSelectedCountry('paraguai')"
                                                           [checked]="handlerCheckCountry('paraguai')"
                                                           [disabled]="handlerDisabledCountry('paraguai')">
                                                    <label class="custom-control-label"
                                                           for="paraguai1">{{'country.paraguai' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="uruguai1"
                                                           (click)="handlerSelectedCountry('uruguai')"
                                                           [checked]="handlerCheckCountry('uruguai')"
                                                           [disabled]="handlerDisabledCountry('uruguai')">
                                                    <label class="custom-control-label"
                                                           for="uruguai1">{{'country.uruguai' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="venezuela"
                                                           (click)="handlerSelectedCountry('venezuela')"
                                                           [checked]="handlerCheckCountry('venezuela')"
                                                           [disabled]="handlerDisabledCountry('venezuela')">
                                                    <label class="custom-control-label"
                                                           for="venezuela">{{'country.venezuela' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="mocambique"
                                                           (click)="handlerSelectedCountry('mocambique')"
                                                           [checked]="handlerCheckCountry('mocambique')"
                                                           [disabled]="handlerDisabledCountry('mocambique')">
                                                    <label class="custom-control-label"
                                                           for="mocambique">{{'country.mocambique' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="angola"
                                                           (click)="handlerSelectedCountry('angola')"
                                                           [checked]="handlerCheckCountry('angola')"
                                                           [disabled]="handlerDisabledCountry('angola')">
                                                    <label class="custom-control-label"
                                                           for="angola">{{'country.angola' | translate}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="handlerCheckCountryVisible('ceam')">
                                        <h6 class="text-primary">{{'general.labelCountry2' | translate}}</h6>
                                        <div class="bg-beige py-3 rounded row mx-0 align-items-center mb-3">
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="belize2"
                                                           (click)="handlerSelectedCountry('belize', 'ceam')"
                                                           [checked]="handlerCheckCountry('belize', 'ceam')"
                                                           [disabled]="handlerDisabledCountry('belize', 'ceam')">
                                                    <label class="custom-control-label"
                                                           for="belize2">{{'country.belize' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="costa-rica2"
                                                           (click)="handlerSelectedCountry('costa-rica', 'ceam')"
                                                           [checked]="handlerCheckCountry('costa-rica', 'ceam')"
                                                           [disabled]="handlerDisabledCountry('costa-rica', 'ceam')">
                                                    <label class="custom-control-label"
                                                           for="costa-rica2">{{'country.costa-rica' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="guatemala2"
                                                           (click)="handlerSelectedCountry('guatemala', 'ceam')"
                                                           [checked]="handlerCheckCountry('guatemala', 'ceam')"
                                                           [disabled]="handlerDisabledCountry('guatemala', 'ceam')">
                                                    <label class="custom-control-label"
                                                           for="guatemala2">{{'country.guatemala' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="honduras2"
                                                           (click)="handlerSelectedCountry('honduras', 'ceam')"
                                                           [checked]="handlerCheckCountry('honduras', 'ceam')"
                                                           [disabled]="handlerDisabledCountry('honduras', 'ceam')">
                                                    <label class="custom-control-label"
                                                           for="honduras2">{{'country.honduras' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="nicaragua2"
                                                           (click)="handlerSelectedCountry('nicaragua', 'ceam')"
                                                           [checked]="handlerCheckCountry('nicaragua', 'ceam')"
                                                           [disabled]="handlerDisabledCountry('nicaragua', 'ceam')">
                                                    <label class="custom-control-label"
                                                           for="nicaragua2">{{'country.nicaragua' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="panama2"
                                                           (click)="handlerSelectedCountry('panama', 'ceam')"
                                                           [checked]="handlerCheckCountry('panama', 'ceam')"
                                                           [disabled]="handlerDisabledCountry('panama', 'ceam')">
                                                    <label class="custom-control-label"
                                                           for="panama2">{{'country.panama' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input"
                                                           id="republica-dominicana2"
                                                           (click)="handlerSelectedCountry('republica-dominicana', 'ceam')"
                                                           [checked]="handlerCheckCountry('republica-dominicana', 'ceam')"
                                                           [disabled]="handlerDisabledCountry('republica-dominicana', 'ceam')">
                                                    <label class="custom-control-label"
                                                           for="republica-dominicana2">{{'country.republica-dominicana' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input"
                                                           id="el-salvador"
                                                           (click)="handlerSelectedCountry('el-salvador', 'ceam')"
                                                           [checked]="handlerCheckCountry('el-salvador', 'ceam')"
                                                           [disabled]="handlerDisabledCountry('el-salvador', 'ceam')">
                                                    <label class="custom-control-label"
                                                           for="el-salvador">{{'country.el-salvador' | translate}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div>
                                <a href="javascript:void('')" class="d-block question_beside" data-toggle="popover"
                                   [attr.data-content]="getTranslateInstantKey('wheninformingcountry')">
                                    <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <!--request-two-->
            <div class="tab-pane fade" id="step2" role="tabpanel" aria-labelledby="step2-tab">

                <div class="row pt-3 px-0 px-md-5" *ngIf="step == 2">
                    <div class="col-12">
                        <span class="step-subtitle">{{'ifyouhavedoubts'|translate}}</span>
                    </div>
                </div>

                <div class="p-4 px-0 px-md-5">

                    <div class="row px-0 px-md-5">
                        <div class="col-md-6">
                            <div class="input-material question"  data-toggle="tooltip"
                                 data-placement="top"
                                 title="{{'escreverseelancamento'|translate}}">
                                <input class="form-control" id="request" type="text" maxlength="200"
                                       [(ngModel)]="modelRequest.motive"
                                       required [disabled]="stepTwoDisabled"/>
                                <label for="request"
                                       [ngClass]="{'disabled' : stepTwoDisabled}">{{'novocadastro.etapa2.input1' | translate}}</label>
                                <div>
                                    <a href="javascript:void('')" class=" question_beside_right d-block "
                                       data-toggle="popover"
                                       [attr.title]="getTranslateInstantKey('popovers.step2.pop1.title')"
                                       [attr.data-content]="getTranslateInstantKey('popovers.step2.pop1.text')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>
                            <div class="input-material question" data-toggle="tooltip"
                                 data-placement="top"
                                 title="{{'paralancamentodesubsidiaria'|translate}}">
                                <select id="channel" class="form-control" name="type"
                                        [(ngModel)]="modelRequest.channelDistribution" [disabled]="stepTwoDisabled">
                                    <option disabled selected
                                            [ngValue]="null">{{'general.selecione' | translate}}</option>
                                    <option *ngFor="let c of listChannel" [ngValue]="c.name">{{c.name}}</option>
                                </select>
                                <label for="channel" [ngClass]="{'disabled' : stepTwoDisabled}"
                                       class="mb-0 mr-3">{{'novocadastro.etapa2.input2' | translate}}</label>
                                <div>
                                    <a href="javascript:void('')" class="question_beside_right d-block"
                                       data-toggle="popover"
                                       [attr.title]="getTranslateInstantKey('popovers.step2.pop2.title')"
                                       [attr.data-content]="getTranslateInstantKey('popovers.step2.pop2.text')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>

                                    </a>
                                </div>
                            </div>
                            <div class="input-material question" data-toggle="tooltip"
                                 data-placement="top"
                                 title="{{'informarmesmaunidadedecodigosap'|translate}}">
                                <select id="unity" class="form-control" name="unity"
                                        [(ngModel)]="modelRequest.unitMeasure" [disabled]="stepTwoDisabled">
                                    <option disabled selected
                                            [ngValue]="null">{{'general.selecione' | translate}}</option>
                                    <option *ngFor="let c of listMeasure" [ngValue]="c.name">{{c.description}}</option>
                                </select>
                                <label for="unity" [ngClass]="{'disabled' : stepTwoDisabled}"
                                       class="mb-0 mr-3">{{'novocadastro.etapa2.input3' | translate}}</label>
                                <div>
                                    <a href="javascript:void('')" class="question_beside_right d-block"
                                       data-toggle="popover"
                                       [attr.title]="getTranslateInstantKey('popovers.step2.pop3.title')"
                                       [attr.data-content]="getTranslateInstantKey('popovers.step2.pop3.text')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 text-right">
                            <div class="text-left mb-2">
                                <p class="font-weight-bold"><span
                                        class="text-secondary">{{'novocadastro.etapa2.input8' | translate}}</span></p>
                                <p>{{'step2.texto1' | translate}}</p>
                            </div>
                            <!--<div class="d-none">
                                <textarea class=" input-material" id="description" style="width: inherit; resize: none"
                                          rows="7" [(ngModel)]="modelRequest.description" [disabled]="stepTwoDisabled"
                                          maxlength="40" required></textarea>
                            </div>-->
                            <input data-toggle="tooltip"
                                   data-placement="top"
                                   title="{{'seguirpassosparaconstrucao'|translate}}" class="form-control" id="descriptionIpt" [(ngModel)]="modelRequest.description"
                                   [readOnly]="true"
                                   type="text" maxlength="200" required/>
                            <div class="w-100 d-block mb-4 mt-4"
                                 *ngIf="!stepTwoDisabled">
                                <a href="javascript:$('#modalDescriptionForm').modal('show'); void('');"
                                   class="btn btn-primary btn-block">{{'novocadastro.etapa2.input8' | translate}}</a>
                            </div>

                            <!--<label for="descriptionIpt">
                                {{'novocadastro.maximo1' | translate}}
                                {{handlerMaximumCharaters(this.modelRequest.description)}}
                                {{'novocadastro.maximo2' | translate}}
                            </label>-->
                        </div>
                    </div>
                    <div class="row px-0 px-md-5">
                        <div class="col-lg-6">
                            <div class="input-material question" data-toggle="tooltip"
                                 data-placement="top"
                                 title="{{'informartitulardoregistro'|translate}}" >
                                <ng-container *ngIf="modelRequest.area_400 !== 'PMOINT'">
                                    <input class="form-control" id="register" type="text" maxlength="200"
                                           [(ngModel)]="modelRequest.recordHolder" [disabled]="stepTwoDisabled" required/>
                                </ng-container>
                                <ng-container *ngIf="modelRequest.area_400 === 'PMOINT'">
                                    <input class="form-control" id="register" type="text" maxlength="200"
                                           [(ngModel)]="modelRequest.countrySolicitationPmoInt" [disabled]="true" required/>
                                </ng-container>
                                <label for="register" class="col-10 p-0 text-truncate"
                                       [ngClass]="{'disabled' : (modelRequest.area_400 === 'PMOINT') || stepTwoDisabled}">
                                    {{ modelRequest.area_400 === 'PMOINT' ? 'País Solicitante' : ('novocadastro.etapa4.secao2.card3.detentor' | translate)}}
                                </label>
                                <div>
                                    <a href="javascript:void('')" class="question_beside_right d-block"
                                       data-toggle="popover"
                                       [attr.title]="getTranslateInstantKey('popovers.step2.pop5.title')"
                                       [attr.data-content]="getTranslateInstantKey('popovers.step2.pop5.text')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-material question" >
                                <input class="form-control" id="registerMinistry" maxlength="200" type="text"
                                       [disabled]="stepTwoDisabled"
                                       [(ngModel)]="modelRequest.ministryRecord" required/>
                                <label for="registerMinistry" class="col-10 p-0 text-truncate"
                                       [ngClass]="{'disabled' : stepTwoDisabled}">{{'novocadastro.etapa4.secao2.card3.registro' | translate}}</label>
                                <div>
                                    <a href="javascript:void('')" class="question_beside d-block" data-toggle="popover"
                                       [attr.title]="getTranslateInstantKey('popovers.step2.pop6.title')"
                                       [attr.data-content]="getTranslateInstantKey('popovers.step2.pop6.text')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>

                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-material question">
                                <ng-container *ngIf="modelRequest.area_400 !== 'PMOINT'">
                                    <select id="Dispatched" class="form-control" name="type" data-toggle="tooltip"
                                            data-placement="top"
                                            title="{{'informarfabricantedoproduto'|translate}}"
                                            [(ngModel)]="modelRequest.dispatchedBy" [disabled]="stepTwoDisabled">
                                        <option disabled selected
                                                [ngValue]="null">{{'general.selecione' | translate}}</option>
                                        <option *ngFor="let c of listDispatched"
                                                [ngValue]="c.name">{{c.description}}</option>
                                    </select>
                                </ng-container>
                                <ng-container *ngIf="modelRequest.area_400 === 'PMOINT'">
                                    <input class="form-control" id="Dispatched" type="text" maxlength="200"
                                           [(ngModel)]="modelRequest.countryManufacturingPmoInt" [disabled]="true" required/>
                                </ng-container>
                                <label for="Dispatched" [ngClass]="{'disabled' : (modelRequest.area_400 === 'PMOINT') || stepTwoDisabled}" class="mb-0 mr-3">
                                    {{modelRequest.area_400 === 'PMOINT' ? 'País Fabricante' : ('novocadastro.etapa4.secao2.card3.expedido' | translate)}}
                                </label>
                                <div>
                                    <a href="javascript:void('')" class="question_beside_right d-block"
                                       data-toggle="popover"
                                       [attr.title]="getTranslateInstantKey('popovers.step2.pop4.title')"
                                       [attr.data-content]="getTranslateInstantKey('popovers.step2.pop4.text')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-material" data-toggle="tooltip"
                                 data-placement="top"
                                 title="{{'indiquedatadefechamento'|translate}}">
                                <input  class="form-control js-daterangepicker" id="validity" type="text" maxlength="10"
                                       mask="00/00/0000"
                                       [disabled]="stepTwoDisabled" required/>

                                <label for="validity" class="text-truncate" style="max-width: 75%"
                                       [ngClass]="{'disabled' : stepTwoDisabled}">{{'novocadastro.etapa4.secao2.card3.validade' | translate}}</label>
                                <div>
                                    <a href="javascript:void('')" data-toggle="popover" class="question_beside d-block"
                                       [attr.title]="getTranslateInstantKey('popovers.step2.pop7.title')"
                                       [attr.data-content]="getTranslateInstantKey('popovers.step2.pop7.text')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>

                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-3 input-material">
                                <input type="number" class="form-control text-primary" min="0" id="dueDate" data-toggle="tooltip"
                                       data-placement="top"
                                       title="{{'informarmesesvidautil'|translate}}"
                                       [(ngModel)]="modelRequest.dueDate" [disabled]="stepTwoDisabled"
                                       oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                       onchange="if (this.value > 60) this.value = 60"
                                       aria-describedby="basic-addon2" maxlength="2">

                                <label for="dueDate"
                                       class="text-truncate" style="max-width: 75%"
                                       [ngClass]="{'disabled' : stepTwoDisabled}">{{'novocadastro.etapa2.input14' | translate}}</label>
                                <div>
                                    <a href="javascript:void('')" class="question_beside_right d-block"
                                       data-toggle="popover"
                                       title="{{'novocadastro.etapa2.input14' | translate}}"
                                       [attr.data-content]="getTranslateInstantKey('step2.p1')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>
                            <div class="input-material d-none">
                                <select id="Deadline" class="form-control" name="type"
                                        [(ngModel)]="modelRequest.dueDate" [disabled]="stepTwoDisabled">
                                    <option disabled selected
                                            [ngValue]="null">{{'general.selecione' | translate}}</option>
                                    <option *ngFor="let d of listDueDate" [ngValue]="d">{{d}}
                                        <span *ngIf="d == 1">Mês</span>
                                        <span *ngIf="d !== 1">Meses</span>
                                    </option>
                                </select>
                                <label for="Deadline" [ngClass]="{'disabled' : stepTwoDisabled}"
                                       class="mb-0 mr-3">{{'novocadastro.etapa2.input13' | translate}}</label>
                            </div>
                        </div>
                        <!--<div class="col-lg-6 col-xl-12">
                            <div class="input-material question">
                                <input class="form-control" id="Manufacturer" type="text" maxlength="200"
                                       [(ngModel)]="modelRequest.manufacturer" [disabled]="stepTwoDisabled" required/>
                                <label for="Manufacturer" class="col-10 p-0 text-truncate"
                                       [ngClass]="{'disabled' : stepTwoDisabled}">{{'novocadastro.etapa2.input7' | translate}}</label>
                                <div>
                                    <a href="javascript:void('')" class="question_beside d-block" data-toggle="popover"
                                       [attr.title]="getTranslateInstantKey('popovers.step2.pop9.title')"
                                       [attr.data-content]="getTranslateInstantKey('popovers.step2.pop9.text')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>

                                    </a>
                                </div>
                            </div>
                        </div>-->
                    </div>
                    <div class="row mt-4 px-0 px-md-5">
                        <div class="col-12 mx-auto" style="max-width: 400px">
                            <!--<a href="javascript:void(0)" *ngIf="!stepTwoDisabled" (click)="request2()"
                               class="btn btn-block btn-warning"
                               type="button">{{'novocadastro.enviar' | translate}}</a>-->
                            <!--<a href="javascript:void(0)" *ngIf="stepTwoDisabled && !btnTwoDisabled"
                               (click)="handlerNext(2)"
                               class="btn btn-block btn-warning"
                               type="button">{{'novocadastro.next' | translate}}</a>-->
                        </div>
                    </div>
                </div>
            </div>
            <!--request-three-->
            <div class="tab-pane fade" id="step3" role="tabpanel" aria-labelledby="step3-tab">
                <div class="p-4 px-0 px-md-5">
                    <div class="row px-0 px-md-5">
                        <div class="col-md-6">
                            <div class="input-material question">

                                <input data-toggle="tooltip"
                                       data-placement="top"
                                       title="{{'escrevaseelancamentoou'|translate}}" class="form-control" id="request3" type="text" maxlength="200"
                                       [(ngModel)]="modelRequest.motive"
                                       required [disabled]="stepThreeDisabled"/>
                                <label for="request"
                                       [ngClass]="{'disabled' : stepThreeDisabled}">{{'novocadastro.etapa2.input1' | translate}}</label>

                                <div>
                                    <a href="javascript:void('')" class="question_beside_right d-block"
                                       data-toggle="popover"
                                       [attr.title]="getTranslateInstantKey('popovers.step2.pop1.title')"
                                       [attr.data-content]="getTranslateInstantKey('popovers.step2.pop1.text')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>

                                    </a>
                                </div>
                            </div>

                            <div class="input-material question col-lg-12 mr-3">
                                <!--<input class="form-control js-daterangepicker" id="validity1" type="text" maxlength="10"
                                       [disabled]="stepThreeDisabled" required/>
                                <label for="validity1"
                                       [ngClass]="{'disabled' : stepThreeDisabled}">{{'novocadastro.etapa4.secao2.card3.validade' | translate}}</label>
                                <div>
                                    <a href="javascript:void('')" class="d-block question_beside_right"
                                       data-toggle="popover"
                                       [attr.title]="getTranslateInstantKey('popovers.step2.pop7.title')"
                                       [attr.data-content]="getTranslateInstantKey('popovers.step2.pop1.text')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                    </a>
                                </div>-->

                                <input data-toggle="tooltip"
                                       data-placement="top"
                                       title="{{'informarmesesdevidautil'|translate}}" type="number" class="form-control text-primary" min="0" id="dueDateR"
                                       [(ngModel)]="modelRequest.dueDateRevised" [disabled]="stepThreeDisabled"
                                       oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                       onchange="if (this.value > 60) this.value = 60"
                                       aria-describedby="basic-addon2" maxlength="2">
                                <label for="dueDateR"
                                       class="text-truncate" style="max-width: 75%"
                                       [ngClass]="{'disabled' : stepThreeDisabled}">{{'novocadastro.etapa3.inputDeadline' | translate}}</label>
                                <div>
                                    <a href="javascript:void('')" class="question_beside_right d-block"
                                       data-toggle="popover"
                                       title="{{'novocadastro.etapa3.inputDeadline' | translate}}"
                                       [attr.data-content]="getTranslateInstantKey('step2.p1')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>

                            <div data-toggle="tooltip"
                                 data-placement="top"
                                 title="{{'portaria344'|translate}}" class="input-material disabled question mt-5" *ngIf="!stepThreeDisabled">
                                <select class="form-control" [(ngModel)]="modelDescriptionPortaria"
                                        (change)="setModelPortariaDescription()" [disabled]="stepThreeDisabled"
                                        required>
                                    <option value="">{{'general.selecione' | translate}}</option>
                                    <option *ngFor="let c of listPort" [ngValue]="c.name">{{c.description}}</option>
                                </select>
                                <label>Portaria 344/98</label>
                                <div>
                                    <a href="javascript:void('')" class="d-block question_beside_right"
                                       data-toggle="popover"
                                       [attr.title]="getTranslateInstantKey('popovers.step3.pop1.title')"
                                       [attr.data-content]="getTranslateInstantKey('popovers.step3.pop1.text')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>


                            <div class="modal-body modal-body--small pt-0">
                                <div class="row flex-column">
                                    <div class="text-center mx-auto font-weight-bold text-primary mt-0 mb-5 size-lg">
                                        Verificação
                                    </div>
                                    <div class="bg-beige rounded pt-3 pb-1 px-10">
                                        <div class="bg-beige rounded pt-3 pb-1 px-4">
                                            <div class="input-material d-flex align-items-center"
                                                 style="min-height: 85px">
                                                <div class="custom-control custom-switch w-100">
                                                    <input type="checkbox" class="custom-control-input"
                                                           id="customSwitch100" [(ngModel)]="isChecked1">
                                                    <label class="custom-control-label" for="customSwitch100">
                                                        {{'popovers.step3.check1' | translate}}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="input-material d-flex align-items-center"
                                                 style="min-height: 85px">
                                                <div class="custom-control custom-switch w-100">
                                                    <input type="checkbox" class="custom-control-input"
                                                           id="customSwitch101" [(ngModel)]="isChecked2">
                                                    <label class="custom-control-label" for="customSwitch101">
                                                        {{'popovers.step3.check2' | translate}}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="input-material d-flex align-items-center"
                                                 style="min-height: 85px">
                                                <div class="custom-control custom-switch w-100">
                                                    <input type="checkbox" class="custom-control-input"
                                                           id="customSwitch102" [(ngModel)]="isChecked3">
                                                    <label class="custom-control-label" for="customSwitch102">
                                                        {{'popovers.step3.check3' | translate}}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="text-left mb-2">
                                <p class="font-weight-bold"><span
                                        class="text-secondary">{{'novocadastro.etapa2.input8' | translate}}</span></p>
                                <p>{{'step2.texto1' | translate}}</p>
                            </div>
                            <input class="form-control" id="descriptionIptStep3" [(ngModel)]="modelRequest.description"
                                   [readOnly]="false" type="text" maxlength="33" required/>

                            <div class="w-100 d-block mb-4 mt-4"
                                 *ngIf="!stepThreeDisabled">
                                <a href="javascript:$('#modalDescriptionForm').modal('show'); void('');"
                                   class="btn btn-primary btn-block">{{'novocadastro.etapa2.input8' | translate}}</a>
                            </div>
                        </div>

                    </div>
                    <div class="d-none">
                        <div class="row px-0 px-md-5">
                            <div class="col-md-6">

                                <div class="input-material question">
                                    <select id="channel3" class="form-control" name="type"
                                            [(ngModel)]="modelRequest.channelDistribution"
                                            [disabled]="stepThreeDisabled">
                                        <option disabled selected value="undefined">Selecione</option>
                                        <option *ngFor="let c of listChannel"
                                                [ngValue]="c.name">{{c.description}}</option>
                                    </select>
                                    <label for="channel" [ngClass]="{'disabled' : stepThreeDisabled}"
                                           class="mb-0 mr-3">{{'novocadastro.etapa2.input2' | translate}}</label>
                                    <div>
                                        <a href="javascript:void('')" class="question_beside_right d-block"
                                           data-toggle="popover"
                                           title="Por favor,  elegir el canal donde será distribuido el producto"
                                           data-content="Generalmente, para operaciones internacionales, el canal correcto es subsidiarias">
                                            <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                        </a>
                                    </div>

                                </div>
                                <div class="input-material question">
                                    <select id="unity3" class="form-control" name="unity"
                                            [(ngModel)]="modelRequest.unitMeasure" [disabled]="stepThreeDisabled">
                                        <option disabled selected
                                                value="undefined">{{'general.selecione' | translate}}</option>
                                        <option *ngFor="let c of listMeasure"
                                                [ngValue]="c.name">{{c.description}}</option>
                                    </select>
                                    <label for="unity" [ngClass]="{'disabled' : stepThreeDisabled}"
                                           class="mb-0 mr-3">{{'novocadastro.etapa2.input3' | translate}}</label>
                                    <div>
                                        <a href="javascript:void('')" class="question_beside_right d-block"
                                           data-toggle="popover"
                                           title="Por favor, informar cual es la unidad básica de medida. "
                                           data-content="Atención, esto campo es muy importante para el catastro y no puede estar equivocado. Si tienes dudas, por favor, haga una consulta a partir del código modelo (transacción MM03, en la primera pantalla)">
                                            <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 text-right">

                            </div>
                        </div>
                        <div class="row px-0 px-md-5">
                            <div class="col-lg-6">
                                <div class="input-material question">
                                    <input class="form-control" id="register3" type="text" maxlength="200"
                                           [disabled]="stepThreeDisabled"
                                           [(ngModel)]="modelRequest.recordHolder" required/>
                                    <label [ngClass]="{'disabled' : stepThreeDisabled}"
                                           for="register">{{'novocadastro.etapa4.secao2.card3.detentor' | translate}}</label>
                                    <div>
                                        <a href="javascript:void('')" class="question_beside_right d-block"
                                           data-toggle="popover"
                                           title="Por favor, informar la operación que realizará el despacho del producto"
                                           data-content="">
                                            <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="input-material question">
                                    <input class="form-control" id="registerMinistry3" maxlength="200" type="text"
                                           [disabled]="stepThreeDisabled"
                                           [(ngModel)]="modelRequest.ministryRecord" required/>
                                    <label [ngClass]="{'disabled' : stepThreeDisabled}"
                                           for="registerMinistry">{{'novocadastro.etapa4.secao2.card3.registro' | translate}}</label>
                                    <div>
                                        <a href="javascript:void('')" class="question_beside d-block"
                                           data-toggle="popover"
                                           title="Por  favor,  informar  el  numero  de  registro  del producto"
                                           data-content="Si  el  registro  aún  no  fue  concedido,  llenar  como “Registro aún no concedido”. En esto caso, una vez que se tenga el numero de lo mismo, es necesario informarle  a  Cadastro  Eurofarma,  por  correo,  (Cadastro.Eurofarma@eurofarma.com) el número del código  SAP  del  producto  y  el  numero  del  registro otorgado para que se pueda añadir esa información">
                                            <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="input-material question">
                                    <select id="Dispatched3" class="form-control" name="type"
                                            [disabled]="stepThreeDisabled"
                                            [(ngModel)]="modelRequest.dispatchedBy">
                                        <option disabled selected
                                                value="undefined">{{'general.selecione' | translate}}</option>
                                        <option *ngFor="let c of listDispatched"
                                                [ngValue]="c.name">{{c.description}}</option>
                                    </select>
                                    <label [ngClass]="{'disabled' : stepThreeDisabled}" for="Dispatched"
                                           class="mb-0 mr-3">
                                        {{'novocadastro.etapa4.secao2.card3.expedido' | translate}}
                                    </label>
                                    <div>
                                        <a href="javascript:void('')" class="question_beside_right d-block"
                                           data-toggle="popover"
                                           title="Por favor, informar la operación que es propietaria del registro del producto"
                                           data-content="">
                                            <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6 ">

                                <!--    <div>
                                        <a href="javascript:void('')"  class="d-block" data-toggle="popover" title="Popover title 1" data-content="And here's some amazing content. It's very engaging. Right?4">
                                            <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                        </a>
                                    </div>-->
                            </div>
                        </div>
                        <div class="row flex-lg-row-reverse px-0 px-md-5">
                            <div class="col-md-12 col-lg-6">
                                <div class="input-group input-material mb-3">
                                    <input type="number" class="form-control text-primary"
                                           [(ngModel)]="modelRequest.dueDate" [disabled]="stepThreeDisabled"
                                           oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                           onchange="if (this.value > 60) this.value = 60"
                                           aria-describedby="basic-addon" maxlength="2">
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon">Meses</span>
                                    </div>
                                </div>
                                <div class="input-material d-none">
                                    <select id="Deadline1" class="form-control" name="type"
                                            [(ngModel)]="modelRequest.dueDate" [disabled]="stepThreeDisabled">
                                        <option disabled selected
                                                [ngValue]="null">{{'general.selecione' | translate}}</option>
                                        <option *ngFor="let d of listDueDate" [ngValue]="d">{{d}}
                                            <span *ngIf="d == 1">Mês</span>
                                            <span *ngIf="d !== 1">Meses</span>
                                        </option>
                                    </select>
                                    <label for="Deadline1" [ngClass]="{'disabled' : stepThreeDisabled}"
                                           class="mb-0 mr-3">{{'novocadastro.etapa2.input13' | translate}}</label>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6">
                                <div class="input-material">
                                    <input class="form-control" id="Manufacturer1" type="text" maxlength="200"
                                           [(ngModel)]="modelRequest.manufacturer" [disabled]="stepThreeDisabled"
                                           required/>
                                    <label for="Manufacturer1" class="col-10 p-0 text-truncate"
                                           [ngClass]="{'disabled' : stepThreeDisabled}">{{'novocadastro.etapa2.input7' | translate}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5 px-0 px-md-5">
                        <div class="col-12 mx-auto" style="max-width: 400px">
                            <!--<a href="javascript:void(0)" (click)="request3()" *ngIf="!stepThreeDisabled"
                               class="btn btn-block btn-warning"
                               type="button">{{'novocadastro.enviar' | translate}}</a>-->
                            <!--<a href="javascript:void(0)" *ngIf="stepThreeDisabled && !btnThreeDisabled"
                               (click)="handlerNext(3)"
                               class="btn btn-block btn-warning"
                               type="button">{{'novocadastro.next' | translate}}</a>-->
                        </div>
                    </div>
                </div>
            </div>
            <!--request-four-->
            <div class="tab-pane fade" id="step4" role="tabpanel" aria-labelledby="step4-tab">
                <div class="row" *ngIf="!stepFourDisabled && !isNullOrUndefined(listLike)">
                    <div class="col-12">
                        <div class="card bg-warning-variant mt-3 size-md border-0 shadow-none">
                            <div class="row p-3">
                                <span class="w-100 text-primary size-xm border-bottom mt-2 mx-4 pb-2">{{'novocadastro.etapa4.subtitle' | translate}}</span>
                                <div class="col-12 pl-4">
                                    <div class="row">
                                        <span class="px-4 mt-3 mb-4">
                                            {{'novocadastro.etapa4.text' | translate}}
                                        </span>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="card border-0 p-2 mt-3 pl-sm-3">
                                        <div class="row">
                                            <div class="col-sm-1 d-flex justify-content-center align-items-center mb-3 mb-sm-0">
                                                <div class="d-block text-center">
                                                    <input class="input_radio" type="radio" id="item"
                                                           name="country" [checked]="radioCheckedDad()"
                                                           (change)="radioChecked('')">
                                                    <label for="item">
                                                        <span *ngIf="radioCheckedDad()">Principal</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col d-flex align-items-center justify-content-center">
                                                <app-country-flags
                                                        [list]="modelRequest.countryNotifications"></app-country-flags>
                                            </div>
                                            <!--<div class="col d-flex align-items-center justify-content-center">
                                                <img style="width: 42px;" class="img-fluid"
                                                     src="assets/images/country/{{handlerFlagImage(modelRequest.country)}}">
                                            </div>-->
                                            <div class="col-sm-4 ml-0 mt-3 mt-sm-0">
                                                <div class="row">
                                                    <span class="col-12 text-gray-text"><span
                                                            class="text-primary d-inline-block mr-1"
                                                            *ngFor="let z of modelRequest.countryNotifications">{{getCountryNameByKey(z)}}</span></span>
                                                    <span class="col-12 text-gray-text">{{'novocadastro2.descritivo' | translate}}
                                                        <strong>{{modelRequest.description}}</strong></span>
                                                    <span class="col-12 text-gray-text">{{'novocadastro.etapa4.card1.prazo' | translate}}
                                                        <strong>{{modelRequest.dueDateRevised}} meses</strong></span>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 d-flex align-items-center justify-content-center justify-content-lg-end mt-4 mt-sm-0">
                                                <div class="row text-center">
                                                    <span class="col-12 text-gray-text size-xs text-right d-flex
                                                    align-items-center justify-content-center">
                                                       {{'novocadastro.etapa4.card1.prenchido' | translate}}
                                                        <br> {{'novocadastro.etapa4.card1.etapa' | translate}}
                                                        <span class="d-flex justify-content-center size-xm ml-2
                                                        align-items-center bg-warning rounded-circle mr-2"
                                                              style="width: 25px;height: 25px;">
                                                            <strong>{{modelRequest.step}}</strong>
                                                        </span>
                                                    </span>
                                                    <span class="col-12 text-primary">
                                                        <strong>{{'novocadastro.etapa3.title' | translate}}</strong>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-1 d-flex justify-content-center mt-3"
                                                 style="height: 29px;">
                                                <label class="input_check">
                                                    <input type="checkbox" id="itemCheck_00" name="country"
                                                           value="item" [checked]="true"
                                                           [disabled]="true">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="card border-0 p-2 mt-3 pl-sm-3"
                                         *ngFor="let l of modelRequest.unifications; let i = index;">
                                        <div class="row">
                                            <div class="col-sm-1 d-flex justify-content-center align-items-center mb-3 mb-sm-0">
                                                <div class="d-block text-center">
                                                    <input class="input_radio" type="radio" id="item_{{i}}"
                                                           name="country" [checked]="l.isDefault"
                                                           (change)="radioChecked(l.request.id)">
                                                    <label for="item_{{i}}">
                                                        <span *ngIf="l.isDefault">Principal</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col d-flex align-items-center justify-content-center">
                                                <app-country-flags
                                                        [list]="l.request.countryNotifications"></app-country-flags>
                                            </div>
                                            <!--<div class="col d-flex align-items-center justify-content-center">
                                                <img style="width: 42px;" class="img-fluid"
                                                     src="assets/images/country/{{handlerFlagImage(l.request.country)}}">
                                            </div>-->
                                            <div class="col-sm-4 ml-0 mt-3 mt-sm-0">
                                                <div class="row">
                                                    <span class="col-12 text-gray-text"><span
                                                            class="text-primary d-inline-block mr-1"
                                                            *ngFor="let z of l.request.countryNotifications">{{getCountryNameByKey(z)}}</span></span>
                                                    <span class="col-12 text-gray-text">{{'novocadastro2.descritivo' | translate}}
                                                        <strong>{{l.request.description}}</strong></span>
                                                    <span class="col-12 text-gray-text">{{'novocadastro.etapa4.card1.prazo' | translate}}
                                                        <strong>{{l.request.dueDateRevised}} meses</strong></span>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 d-flex align-items-center justify-content-center justify-content-lg-end mt-4 mt-sm-0">
                                                <div class="row text-center">
                                                    <span class="col-12 text-gray-text size-xs text-right d-flex
                                                    align-items-center justify-content-center">
                                                       {{'novocadastro.etapa4.card1.prenchido' | translate}}
                                                        <br> {{'novocadastro.etapa4.card1.etapa' | translate}}
                                                        <span class="d-flex justify-content-center size-xm ml-2
                                                        align-items-center bg-warning rounded-circle mr-2"
                                                              style="width: 25px;height: 25px;">
                                                            <strong>{{l.request.step}}</strong>
                                                        </span>
                                                    </span>
                                                    <span class="col-12 text-primary">
                                                        <strong>{{'novocadastro.etapa3.title' | translate}}</strong>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-1 d-flex justify-content-center mt-3"
                                                 style="height: 29px;">
                                                <label class="input_check">
                                                    <input type="checkbox" id="itemCheck_{{i}}" name="country"
                                                           value="item_{{i}}" [checked]="l.isSelected"
                                                           [disabled]="l.isDefault"
                                                           [(ngModel)]="l.isSelected">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mx-auto p-4 tab-pane-content">
                    <div class="row mt-3">
                        <div class="col-12">
                            <div class="input-material disabled">
                                <input data-toggle="tooltip"
                                       data-placement="top"
                                       title="{{'sehouvernecessidadedecambio'|translate}}" class="form-control" id="descriptionIptStep4"
                                       [(ngModel)]="modelRequestResult.description"
                                       [disabled]="stepFourDisabled"
                                       (change)="handlerStringSubscr($event, 40)"
                                       (keyup)="handlerStringSubscr($event, 40)"
                                       type="text" maxlength="40" required/>
                                <label>{{'novocadastro.etapa2.input8' | translate}}</label>
                            </div>

                            <!--<div class="w-100 d-block mb-0 mt-0" *ngIf="!stepFourDisabled">
                                <a href="javascript:$('#modalDescriptionForm').modal('show'); void('');"
                                   class="btn btn-primary btn-block">{{'novocadastro.etapa2.input8' | translate}}</a>
                            </div>-->
                        </div>
                        <!--<div class="col-12 mb-4" *ngIf="!stepFourDisabled">
                            <div class="input-material disabled question mt-5">
                                <select class="form-control" [(ngModel)]="modelDescriptionPortaria"
                                        (change)="setModelPortariaDescription()" [disabled]="stepFourDisabled"
                                        required>
                                    <option value="">Selecione</option>
                                    <option *ngFor="let c of listPort" [value]="c.name">{{c.description}}</option>
                                </select>
                                <label>Portaria 344/98</label>
                                <div>
                                    <a href="javascript:void('')" class="d-block question_beside_right"
                                       data-toggle="popover" title="Portaria"
                                       data-content="Conteudo conteudo conteudo....">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>
                        </div>-->
                        <div class="col-12">
                            <div data-toggle="tooltip"
                                 data-placement="top"
                                 title="{{'coloquenumerodecodigodebarras'|translate}}"  class="input-material">
                                <input class="form-control" id="EAN" type="text"
                                       (change)="handlerEan13($event)"
                                       (keyup)="handlerEan13($event)"
                                       maxlength="13"
                                       [(ngModel)]="modelRequest.ean"
                                       [disabled]="stepFourDisabled"
                                       required/>
                                <label for="EAN" [ngClass]="{'disabled': stepFourDisabled}">EAN 13</label>
                            </div>
                            <div class="input-material">
                                <input data-toggle="tooltip"
                                       data-placement="top"
                                       title="{{'ean13'|translate}}" class="form-control" id="DUM" type="text"
                                       (change)="handlerCharSubscr($event, 14)" (keyup)="handlerCharSubscr($event, 14)"
                                       maxlength="14"
                                       [(ngModel)]="modelRequest.dum"
                                       required [disabled]="true"/>
                                <label for="DUM" [ngClass]="{'disabled': true}">DUM 14</label>
                            </div>
                            <div class="input-material">
                                <input data-toggle="tooltip"
                                       data-placement="top"
                                       title="{{'setemalgumaobservacao'|translate}}" class="form-control" id="unificationObservation" type="text"
                                       maxlength="200"
                                       [(ngModel)]="modelRequest.unificationObservation"
                                       [disabled]="stepFourDisabled"
                                       required/>
                                <label for="unificationObservation" [ngClass]="{'disabled': stepFourDisabled}">
                                    {{'novocadastro.etapa4.unificationObservation' | translate}}
                                </label>
                            </div>

                        </div>
                    </div>

                    <div class="row mt-4">
                        <span style="margin-bottom: 2rem;" *ngIf="!stepFourDisabled">
                        {{ 'novocadastro.etapa4.text' | translate }}
                        </span>
                        <div class="col-12 mx-auto" style="max-width: 400px">
                            <!--<a href="javascript:void(0)" (click)="request4()" *ngIf="!stepFourDisabled"
                               class="btn btn-block btn-warning"
                               type="button">{{'novocadastro.enviar' | translate}}</a>-->
                            <!--<a href="javascript:void(0)" (click)="handlerNext(4)"
                               *ngIf="(stepFourDisabled && !btnFourDisabled) || !btnFourDisabled"
                               class="btn btn-block btn-warning"
                               type="button">{{'novocadastro.next' | translate}}</a>-->
                        </div>
                    </div>
                </div>

                <div class="row py-4">
                    <div class="col-12 col-md-6">
                        <span class="w-100 text-primary size-xm border-bottom mt-2 mx-4 pb-2">{{'general.countryAdditionalTitle' | translate}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="custom-control custom-switch custom-switch-single">
                            <input type="checkbox" id="step4Country"
                                   class="custom-control-input"
                                   (change)="toogleIsCountryAdd()"
                                   [checked]="isStepFourCountryAdd"
                                   [disabled]="stepFourDisabled"
                            />
                            <label for="step4Country"
                                   class="custom-control-label pt-1">{{'general.countryAdditionalText' | translate}}</label>
                        </div>
                    </div>
                    <div class="col-12 mt-4" *ngIf="isStepFourCountryAdd">
                        <div class="bg-beige p-2 px-1 px-xs-5 rounded">
                            <div class="assign-team my-4">
                                <ng-container *ngFor="let n of modelDescriptionCountry">
                                    <a class="d-inline-flex align-items-center justify-content-center flex-column m-3"
                                       href="javascript:void('');"
                                       *ngIf="hanlderShowCountryAdd(n.description)"
                                       (click)="handlerSelectedCountryAdd(n.name, n.description)"
                                       [ngClass]="{'active' : isSelectedCountryAddActive(n.description)}"
                                    >
                                        <!--<svg-icon src="assets/images/country/{{n.thumb}}"></svg-icon>-->
                                        <img src="assets/images/country/{{n.thumb}}" alt="" />
                                        <span>{{n.name}}</span>
                                    </a>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!--request-five-->
            <div class="tab-pane fade" id="step5" role="tabpanel" aria-labelledby="step5-tab">
                <div class="row mt-4">
                    <div class="col-md-7">
                        <div class="px-3 py-4" style="max-height: 464px;overflow: auto;">
                            <div class="card bg-warning border-0 shadow-none">
                                <div class="row p-3">
                                    <strong class="col size-md">{{'novocadastro.etapa4.secao2.card1.criado' | translate}}</strong>
                                    <span class="col text-right size-md">{{modelRequest.createdAt | date: 'dd/MM/yyyy'}}</span>
                                    <span class="col-12 size-xm pl-4">{{modelRequest.createdBy?.name}}</span>
                                </div>
                            </div>
                            <div class="card bg-warning-variant mt-3 size-md border-0 shadow-none ">
                                <div class="row p-3">
                                    <strong class="col-12">{{'novocadastro.etapa4.secao2.card2.title' | translate}}</strong>
                                    <div class="col-12 pl-4">
                                        <div class="row">
                                            <span class="col-12 mt-2">{{'novocadastro.etapa4.secao2.card2.nome' | translate}}
                                                <span class="text-primary">{{modelRequest.name}}</span>
                                            </span>
                                            <span class="col-12 mt-2">{{'general.codigomodelo' | translate}}:
                                                <span class="text-primary">{{modelRequest.code}}</span>
                                            </span>
                                            <div class="col-12 mt-2">
                                                <span>{{'general.countryRequest' | translate}}:
                                                    <span class="text-primary"
                                                          *ngFor="let z of modelRequest.countryNotifications">{{getCountryNameByKey(z)}}</span>
                                                </span>
                                                <!--<img class="mr-2" width="30"
                                                     src="assets/images/country/{{handlerFlagImage(modelRequest.country)}}"
                                                     alt="country"/>
                                                <span>{{'novocadastro.etapa4.secao2.card2.pais' | translate}}
                                                    <span class="text-primary">{{modelRequest.country}}</span>
                                                </span>-->
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="card bg-warning-variant mt-3 size-md border-0 shadow-none ">
                                <div class="row p-3">
                                    <strong class="col-12">AR Local</strong>
                                    <div class="col-12 pl-4">
                                        <div class="row">
                                            <span class="col-12 mt-2">{{'step4.sidecard.Arlocal.motivo' | translate}}</span>
                                            <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{modelRequestResult.motive}}</span>
                                            <span class="col-12 mt-2">{{'step4.sidecard.Arlocal.canal' | translate}} </span>
                                            <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{modelRequestResult.channelDistribution}}</span>
                                            <span class="col-12 mt-2">{{'novocadastro2.descritivo' | translate}}</span>
                                            <span class="col-12 mt-2 pl-4 text-primary">{{modelRequestResult.description}}</span>
                                            <span class="col-12 mt-2">{{'step4.sidecard.Arlocal.unidade' | translate}}</span>
                                            <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{modelRequestResult.unitMeasure}}</span>
                                            <span class="col-12 mt-2">{{'step4.sidecard.Arlocal.detentor' | translate}}</span>
                                            <span class="col-12 mt-2 pl-4 text-primary">{{modelRequestResult.recordHolder}}</span>
                                            <span class="col-12 mt-2">{{'step4.sidecard.Arlocal.expedido' | translate}}</span>
                                            <span class="col-12 mt-2 pl-4 text-primary">{{modelRequestResult.dispatchedBy}}</span>
                                            <span class="col-12 mt-2">{{'step4.sidecard.Arlocal.registro' | translate}}</span>
                                            <span class="col-12 mt-2 pl-4 text-primary">{{modelRequestResult.ministryRecord}}</span>
                                            <span class="col-12 mt-2">{{'step4.sidecard.Arlocal.validade' | translate}}</span>
                                            <span class="col-12 mt-2 pl-4 text-primary">{{modelRequestResult.recordValidity | date: 'dd/MM/yyyy'}}</span>
                                            <!--<span class="col-12 mt-2">{{'step4.sidecard.Arlocal.fabricante' | translate}}</span>
                                            <span class="col-12 mt-2 pl-4 text-primary">{{modelRequestResult.manufacturer}}</span>-->
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="card bg-warning-variant mt-3 size-md border-0 shadow-none ">
                                <div class="row p-3">
                                    <strong class="col-12">Artes</strong>
                                    <div class="col-12 pl-4">
                                        <div class="row">
                                            <span class="col-12 mt-2">EAN 13:
                                                <span class="text-primary">{{modelRequest.ean}}</span>
                                            </span>
                                            <span class="col-12 mt-2">DUM 14:
                                                <span class="text-primary">{{modelRequest.dum}}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <strong class="col-12 mt-3 mb-2">{{'general.registerUnify' | translate}}</strong>
                                    <div class="col-12">
                                        <div class="card p-2 pl-4 mt-3 pl-sm-3"
                                             *ngFor="let u of modelRequest.group">
                                            <div class="row">
                                                <!--<div class="col d-flex align-items-center justify-content-center pr-2">
                                                    <img style="width: 42px;" class="img-fluid"
                                                         src="assets/images/country/{{handlerFlagImage(u.country)}}">
                                                </div>-->
                                                <div class="col-sm-8 ml-0 mt-3 mt-sm-0">
                                                    <div class="row">
                                                        <span class="col-12 text-gray-text">{{u.country}}</span>
                                                        <span class="col-12 text-gray-text">{{'novocadastro2.descritivo' | translate}}
                                                            : <strong>{{u.description}}</strong></span>
                                                        <span class="col-12 text-gray-text">{{'novocadastro.etapa2.input13' | translate}}
                                                            :
                                                            <strong>{{u.dueDate}} meses</strong></span>
                                                    </div>

                                                </div>
                                                <div class="col-sm-4 d-flex align-items-center justify-content-center justify-content-lg-end mt-4 mt-sm-0">
                                                    <div class="row text-center">
                                                        <span class="col-12 text-gray-text size-xs">{{'novocadastro.etapa4.card1.prenchido' | translate}}
                                                            até</span>
                                                        <span class="col-12 d-flex align-items-center justify-content-center text-primary">
                                                            <span class="d-flex justify-content-center align-items-center bg-warning rounded-circle mr-2"
                                                                  style="width: 25px;height: 25px;">
                                                                <strong>{{u.step}}</strong>
                                                            </span>
                                                            <strong>AR Interinacional</strong>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="pl-5 my-4 pt-2 pr-5 border-left border-gray-text">
                            <div class="bg-success text-white rounded d-flex justify-content-center px-2 mb-2"
                                 *ngIf="modelRequest.status == 'approve'" style="width: fit-content">
                                <svg-icon src="assets/svg/check.svg"></svg-icon>
                                <span class="ml-1">{{'minhalista.concluido' | translate}}</span>
                            </div>

                            <ng-container *ngIf="modelRequest.status == 'disapprove'">
                                <div class="bg-danger text-white rounded d-flex justify-content-center px-2 mb-2"
                                     style="width: fit-content">
                                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                                    <span class="ml-1">{{'minhalista.reprovado' | translate}}</span>
                                </div>
                                <div class="mt-3 mb-4">
                                    <p><strong>{{'general.step' | translate}}
                                        :</strong> {{getNameStep(modelRequest.stepReprove)}}</p>
                                    <p><strong>{{'general.justify' | translate}}
                                        :</strong> {{modelRequest.descriptionFinish}}</p>
                                </div>
                            </ng-container>

                            <div class="mb-4">
                                <a href="javascript:void('');" class="btn btn-block btn-primary" (click)="getExcel()">Exportar</a>
                            </div>

                            <ng-container *ngIf="modelRequest.status !== 'disapprove'">
                                <div class="input-material">
                                    <input class="form-control" id="SAP" type="text" maxlength="10"
                                           [disabled]="stepFiveDisabled"
                                           [(ngModel)]="modelRequest.codeSap"
                                           required/>
                                    <label for="SAP"
                                           [ngClass]="{'disabled': stepFiveDisabled}">{{'general.codeSAPCreate' | translate}}</label>
                                </div>
                                <!--                                <div class="input-material">-->
                                <!--                                    <input class="form-control" id="code400" type="text" maxlength="100"-->
                                <!--                                           [disabled]="stepFiveDisabled"-->
                                <!--                                           [(ngModel)]="modelRequest.code_400"-->
                                <!--                                           required/>-->
                                <!--                                    <label for="code400"-->
                                <!--                                           [ngClass]="{'disabled' : stepOneDisabled}">{{'general.codigo400' | translate}}*</label>-->
                                <!--                                </div>-->
                                <!--                                <div class="input-material">-->
                                <!--                                    <input class="form-control" id="motive400" type="text" maxlength="100"-->
                                <!--                                           [disabled]="stepFiveDisabled"-->
                                <!--                                           [(ngModel)]="modelRequest.motive_400"-->
                                <!--                                           required/>-->
                                <!--                                    <label for="motive400"-->
                                <!--                                           [ngClass]="{'disabled' : stepOneDisabled}">{{'general.motive400' | translate}}*</label>-->
                                <!--                                </div>-->
                            </ng-container>
                            <ng-container
                                    *ngIf="modelRequest.status == 'open' || isNullOrUndefined(modelRequest.status)">
                                <a href="javascript:void(0)" (click)="requestFinish('approve')"
                                   class="btn btn-block btn-warning mt-5"
                                   type="button">{{'novocadastro2.aprovar' | translate}}</a>
                                <a (click)="openModal()"
                                   class="btn btn-block btn-outline-primary mt-3"
                                   type="button">{{'novocadastro2.reprovar' | translate}}</a>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ng-container *ngIf="modelRequest.status == 'disapprove' && (step === modelRequest.step && step < 5)">
            <div class="d-flex flex-column align-items-center justify-content-center mb-4">
                <div class="bg-danger text-white rounded d-flex justify-content-center px-2 mb-2"
                     style="width: fit-content">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                    <span class="ml-1">{{'minhalista.reprovado' | translate}}</span>
                </div>
                <div class="mt-3 mb-4">
                    <p><strong>{{'general.step' | translate}}
                        :</strong> {{getNameStep(modelRequest.stepReprove)}}</p>
                    <p><strong>{{'general.justify' | translate}}
                        :</strong> {{modelRequest.descriptionFinish}}</p>
                </div>
            </div>
        </ng-container>

        <div class="col-12 mb-4 text-right"
             *ngIf="step > 1 && step < 5 && stepTabSend && (modelRequest.status === 'open' || isNullOrUndefined(modelRequest.status))">
            <a (click)="openModal()"
               class="btn btn-outline-danger my-3"
               type="button">{{'novocadastro2.reprovar' | translate}}</a>
        </div>
        <div class="col-12 mx-auto mb-4" style="max-width: 400px"
             *ngIf="modelRequest.status !== 'disapprove' && modelRequest.status !== 'approve'">
            <a href="javascript:void(0)" (click)="actionSendTab()"
               *ngIf="step < 5 && (stepTabSend && (modelRequest.status === 'open' || isNullOrUndefined(modelRequest.status)))"
               class="btn btn-block btn-warning"
               type="button">{{'novocadastro.enviar' | translate}}</a>
            <a href="javascript:void(0)" (click)="actionNextTab()" *ngIf="stepTabNext"
               class="btn btn-block btn-warning"
               type="button">{{'novocadastro.next' | translate}}</a>
        </div>

    </div>

    <div class="bg-beige px-3 py-4" *ngIf="step == 4 || step == 5"
         style="width: 100%;min-width: 40%;overflow: auto;min-height: 709px;">
        <div class="card bg-warning border-0 shadow-none">
            <div class="row p-3">
                <strong class="col size-md">Criado por</strong>
                <span class="col text-right size-md">{{modelRequest.createdAt | date: 'dd/MM/yyyy'}}</span>
                <span class="col-12 size-xm pl-4">{{modelRequest.createdBy?.name}}</span>
            </div>
        </div>
        <div class="card bg-warning-variant mt-3 size-md border-0 shadow-none ">
            <div class="row p-3">
                <strong class="col-12">Comercial Local</strong>
                <div class="col-12 pl-4">
                    <div class="row">
                        <span class="col-12 mt-2">{{'step4.sidecard.comercial-local.nome' | translate}}
                            <span class="text-primary">{{modelRequestResult.name}}</span>
                        </span>
                        <span class="col-12 mt-2">{{'general.codigomodelo' | translate}}
                            <span class="text-primary">{{modelRequestResult.code}}</span>
                        </span>
                        <div class="col-12 mt-2">
                            <span> {{'general.countryRequest' | translate}}
                                <span class="text-primary d-inline-block mr-2"
                                      *ngFor="let x of modelRequestResult.countryNotifications">{{getCountryNameByKey(x)}}</span>
                            </span>
                            <!--<img class="mr-2" width="30"
                                 src="assets/images/country/{{handlerFlagImage(modelRequestResult.country)}}" alt="country"/>
                            <span>{{'step4.sidecard.comercial-local.pais' | translate}}
                                <span class="text-primary">{{modelRequestResult.country}}</span>
                            </span>-->
                        </div>

                    </div>
                </div>

            </div>
        </div>
        <div class="card bg-warning-variant mt-3 size-md border-0 shadow-none ">
            <div class="row p-3">
                <strong class="col-12">AR Local</strong>
                <div class="col-12 pl-4">
                    <div class="row">
                        <span class="col-12 mt-2">{{'step4.sidecard.Arlocal.motivo' | translate}}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{modelRequestResult.motive}}</span>
                        <span class="col-12 mt-2">{{'step4.sidecard.Arlocal.canal' | translate}}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{modelRequestResult.channelDistribution}}</span>
                        <span class="col-12 mt-2">{{'novocadastro2.descritivo' | translate}}</span>
                        <span class="col-12 mt-2 pl-4 text-primary">{{modelRequestResult.description}}</span>
                        <span class="col-12 mt-2">{{'step4.sidecard.Arlocal.unidade' | translate}}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{modelRequestResult.unitMeasure}}</span>
                        <span class="col-12 mt-2">{{'step4.sidecard.Arlocal.detentor' | translate}}</span>
                        <span class="col-12 mt-2 pl-4 text-primary">{{modelRequestResult.recordHolder}}</span>
                        <span class="col-12 mt-2">{{'step4.sidecard.Arlocal.expedido' | translate}}</span>
                        <span class="col-12 mt-2 pl-4 text-primary">{{modelRequestResult.dispatchedBy}}</span>
                        <span class="col-12 mt-2">{{'step4.sidecard.Arlocal.registro' | translate}}</span>
                        <span class="col-12 mt-2 pl-4 text-primary">{{modelRequestResult.ministryRecord}}</span>
                        <span class="col-12 mt-2">{{'step4.sidecard.Arlocal.validade' | translate}}</span>
                        <span class="col-12 mt-2 pl-4 text-primary">{{modelRequestResult.recordValidity | date: 'dd/MM/yyyy'}}</span>
                        <!--<span class="col-12 mt-2">{{'step4.sidecard.Arlocal.fabricante' | translate}}</span>
                        <span class="col-12 mt-2 pl-4 text-primary">{{modelRequestResult.manufacturer}}</span>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!--Modal modalRegisterJustify -->
<div class="modal-custom modal fade" id="modalRegisterJustify" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalRegisterJustifyLabel"
     aria-hidden="true"

>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <a href="javascript:void('Fechar');" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <div class="row flex-column">
                    <div class="text-center mx-auto font-weight-bold text-primary mt-2 mb-5 size-lg">
                        {{'general.justifyArea' | translate}}
                    </div>

                    <div class="bg-beige rounded pt-3 pb-1 px-4">
                        <div class="input-material mb-1">
                            <select class="form-control" [(ngModel)]="modelRequest.stepReprove">
                                <option [ngValue]="undefined">Selecione</option>
                                <ng-container *ngFor="let x of [1,2,3,4,5];">
                                    <option *ngIf="x < modelRequest.step +1" [ngValue]="x ">{{x}}
                                        - {{getNameStepByNumber(x)}}</option>
                                </ng-container>
                            </select>
                            <label class="mb-0 mr-3">Etapa</label>
                        </div>
                    </div>
                    <div class="text-center mx-auto font-weight-bold text-primary mt-2 mb-5 size-lg">
                        {{'general.justifyReprove' | translate}}
                    </div>
                    <div class="py-3 bg-beige rounded pb-5 px-4">
                        <div class="text-left mb-2">
                            <span class="text-secondary">{{'novocadastro2.descritivo' | translate}}</span>
                        </div>
                        <textarea class="input-material mb-0" id="description3"
                                  style="width: 100%; resize: none"
                                  rows="7" [(ngModel)]="modelRequest.descriptionFinish"
                                  maxlength="900" required></textarea>
                        <label class=" w-100 text-right" for="description3"
                        >
                            {{'novocadastro.maximo1' | translate}}
                            {{handlerMaximumCharaters(this.modelRequest.descriptionFinish)}}
                            {{'novocadastro.maximo2' | translate}}</label>
                    </div>
                    <a href="javascript:void('');" class="btn btn-block btn-primary mt-5"
                       (click)="requestFinish('disapprove')">Enviar</a>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Modal Description Form-->
<div class="modal-custom modal fade" id="modalDescriptionForm" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalDescriptionFormLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="subTitle text-gray-text">{{'modal.title' | translate}}</h3>
                <a href="javascript:void('Fechar');" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </a>
            </div>
            <div class="modal-body modal-body--small p-0">
                <div class="row mx-0 flex-column">
                    <div class="py-3 rounded pb-5 px-0 px-xs-4">

                        <div class="nav_step-modal shadow mx-auto mb-4 position-relative">
                            <div class="text-right m-auto description">
                                <div class="text-center mx-auto font-weight-bold text-primary primary"
                                     style="height: 48px;">
                                    {{getDescriptionString()}}
                                </div>
                                <hr class="m-0">
                                <small class="text-right"
                                       *ngIf="((getDescriptionString().split('').length - 3) - 40) * (-1) >= 0">Restam {{ ((getDescriptionString().split('').length - 3) - 40) * (-1) }}
                                    caracteres.</small>
                                <small class="text-right"
                                       *ngIf="((getDescriptionString().split('').length - 3) - 40) * (-1) < 0">{{'modal.alert.quartenary' | translate}}</small>
                            </div>

                            <div class="position-relative" style="top: 10px;">
                                <ul id="mytabF-modal" role="tablist" class="nav d-flex justify-content-between">
                                    <li class="text-center">
                                        <a id="step1-tabF-modal" data-toggle="tab" href="#step1-modal" role="tab"
                                           aria-controls="step1-modal"
                                           aria-selected="true" class="nav-link-modal nav_step-secondary-item active">
                                            <span class="step">1</span>
                                            <div class="position_item">
                                                <span class="position_item-stage">{{'modal.step1.label' | translate}}</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="text-center">
                                        <a id="step2-tabF-modal" data-toggle="tab" href="#step2-modal" role="tab"
                                           aria-controls="step2-modal"
                                           aria-selected="false" class="nav-link-modal nav_step-secondary-item">
                                            <span class="step">2</span>
                                            <div class="position_item">
                                                <span class="position_item-stage">{{'modal.step2.title' | translate}}</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="text-center">
                                        <a id="step3-tabF-modal" data-toggle="tab" href="#step3-modal" role="tab"
                                           aria-controls="step3-modal"
                                           aria-selected="false" class="nav-link-modal nav_step-secondary-item">
                                            <span class="step">3</span>
                                            <div class="position_item">
                                                <span class="position_item-stage">{{'modal.step3.title' | translate}}</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="text-center">
                                        <a id="step4-tabF-modal" data-toggle="tab" href="#step4-modal" role="tab"
                                           aria-controls="step4-modal"
                                           aria-selected="false" class="nav-link-modal nav_step-secondary-item">
                                            <span class="step">4</span>
                                            <div class="position_item">
                                                <span class="position_item-stage">{{'modal.step4.title' | translate}}</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="text-center">
                                        <a id="step5-tabF-modal" data-toggle="tab" href="#step5-modal" role="tab"
                                           aria-controls="step5-modal"
                                           aria-selected="false" class="nav-link-modal nav_step-secondary-item">
                                            <span class="step">5</span>
                                            <div class="position_item position_item_right">
                                                <span class="position_item-stage">{{'modal.step5.title' | translate}}</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="text-center">
                                        <a id="step6-tabF-modal" data-toggle="tab" href="#step6-modal" role="tab"
                                           aria-controls="step6-modal"
                                           aria-selected="false" class="nav-link-modal nav_step-secondary-item">
                                            <span class="step">6</span>
                                            <div class="position_item position_item_right">
                                                <span class="position_item-stage">{{'modal.step6.title' | translate}}</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="text-center">
                                        <a id="step7-tabF-modal" data-toggle="tab" href="#step7-modal" role="tab"
                                           aria-controls="step7-modal"
                                           aria-selected="false" class="nav-link-modal nav_step-secondary-item">
                                            <span class="step">7</span>
                                            <div class="position_item position_item_right">
                                                <span class="position_item-stage">{{'modal.step7.title' | translate}}</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="text-center">
                                        <a id="step8-tabF-modal" data-toggle="tab" href="#step8-modal" role="tab"
                                           aria-controls="step8-modal"
                                           aria-selected="false" class="nav-link-modal nav_step-secondary-item">
                                            <span class="step">8</span>
                                            <div class="position_item position_item_right">
                                                <span class="position_item-stage">{{'modal.step8.title' | translate}}</span>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <hr class="bg-primary w-90 mx-auto">

                        <div class="col-12 col-xl-10 mx-auto mt-4">
                            <div class="tab-content" id="myTabContent-modal">
                                <!--request-one-->
                                <div class="tab-pane fade show active" id="step1-modal" role="tabpanel"
                                     aria-labelledby="step1-tab-modal">
                                    <div class="row">
                                        <!--<div class="col-12 mt-5 mb-4">
                                            <h3 class="subTitle">{{'modal.step1.label' | translate}}</h3>
                                        </div>-->
                                        <div class="col-md-12 col-lg-6">
                                            <p><strong> {{'modal.step1.title1' | translate}}</strong></p>
                                            <p>{{'modal.step1.text1' | translate}}</p>
                                            <p><strong> {{'modal.step1.title2' | translate}}</strong></p>
                                            <p> {{'modal.step1.text2' | translate}}</p>
                                            <p><strong> {{'modal.step1.exemple' | translate}}</strong></p>
                                            <p><b class="yellow_back">Alendronato</b> de sódio = <b class="yellow_back">ALENDRONATO</b>
                                                SOD</p>
                                            <p>Cloridrato de <b class="yellow_back">amitriptilina</b> = <b
                                                    class="yellow_back">AMITRIPTLINA</b> CLOR</p>
                                        </div>
                                        <div class="col-md-12 col-lg-6">
                                            <div class="bg-beige p-2 px-1 px-xs-5 rounded">
                                                <div class="input-material my-5">
                                                    <input class="form-control text-uppercase" maxlength="200"
                                                           type="text"
                                                           [(ngModel)]="modelDescription.step0" required/>
                                                    <label>{{'modal.step1.label' | translate}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--request-two-->
                                <div class="tab-pane fade" id="step2-modal" role="tabpanel"
                                     aria-labelledby="step2-tab-modal">
                                    <div class="row justify-content-center">
                                        <!--<div class="col-12 mt-5 mb-4">
                                            <h3 class="subTitle">{{'modal.step2.title' | translate}}</h3>
                                        </div>-->
                                        <div class="col-md-12 col-lg-4 d-flex align-items-center">
                                            <p>{{'modal.step2.text' | translate}}</p>
                                        </div>
                                        <div class="col-md-12 col-lg-7">
                                            <div class="bg-beige p-2 px-1 px-xs-3 rounded">
                                                <div class="input-material mt-4">
                                                    <input class="form-control" maxlength="200" type="text"
                                                           [ngModel]="modelDescription.step1"
                                                           (ngModelChange)="handlerBlockLetters($event,'step1')"
                                                           appOnlyLetters
                                                           required/>
                                                    <label>{{'modal.step2.label2' | translate}}</label>
                                                </div>
                                                <div class="input-material align-items-center d-flex"
                                                     *ngIf="!isNullOrUndefined(modelDescription.step1)">
                                                    <div class="w-100">
                                                        <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                            <input type="radio" class="custom-control-input"
                                                                   name="stepDescriptionConcentration2Radio"
                                                                   (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1lbl')"
                                                                   [value]="'%'"
                                                                   [ngModel]="modelDescription.step1lbl"
                                                            />
                                                            <label class="custom-control-label">%</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                            <input type="radio" class="custom-control-input"
                                                                   name="stepDescriptionConcentration2Radio"
                                                                   (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1lbl')"
                                                                   [value]="'MG'"
                                                                   [ngModel]="modelDescription.step1lbl"
                                                            />
                                                            <label class="custom-control-label">MG</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                            <input type="radio" class="custom-control-input"
                                                                   name="stepDescriptionConcentration2Radio"
                                                                   (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1lbl')"
                                                                   [value]="'MCG'"
                                                                   [ngModel]="modelDescription.step1lbl"
                                                            />
                                                            <label class="custom-control-label">MCG</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                            <input type="radio" class="custom-control-input"
                                                                   name="stepDescriptionConcentration2Radio"
                                                                   (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1lbl')"
                                                                   [value]="'G'"
                                                                   [(ngModel)]="modelDescription.step1lbl"
                                                            />
                                                            <label class="custom-control-label">G</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                            <input type="radio" class="custom-control-input"
                                                                   name="stepDescriptionConcentration2Radio"
                                                                   (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1lbl')"
                                                                   [value]="'ML'"
                                                                   [(ngModel)]="modelDescription.step1lbl"
                                                            />
                                                            <label class="custom-control-label">ML</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                            <input type="radio" class="custom-control-input"
                                                                   name="stepDescriptionConcentration2Radio"
                                                                   (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1lbl')"
                                                                   [value]="'L'"
                                                                   [(ngModel)]="modelDescription.step1lbl"
                                                            />
                                                            <label class="custom-control-label">L</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                            <input type="radio" class="custom-control-input"
                                                                   name="stepDescriptionConcentration2Radio"
                                                                   (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1lbl')"
                                                                   [value]="'UI'"
                                                                   [(ngModel)]="modelDescription.step1lbl"
                                                            />
                                                            <label class="custom-control-label">UI</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                            <input type="radio" class="custom-control-input"
                                                                   name="stepDescriptionConcentration2Radio"
                                                                   (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1lbl')"
                                                                   [value]="'MG/ML'"
                                                                   [(ngModel)]="modelDescription.step1lbl"
                                                            />
                                                            <label class="custom-control-label">MG/ML</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <hr/>

                                                <div class="custom-control custom-switch custom-switch-single">
                                                    <input type="checkbox" id="step1ConcAct2"
                                                           class="custom-control-input"
                                                           (ngModelChange)="actionisChangeDescriptionConcentration()"
                                                           [value]="false"
                                                           [(ngModel)]="modelDescription.step1isB"
                                                    />
                                                    <label for="step1ConcAct2"
                                                           class="custom-control-label">{{'modal.step2.label3' | translate}}</label>
                                                </div>
                                                <ng-container *ngIf="modelDescription.step1isB">
                                                    <div class="input-material mt-4">
                                                        <input class="form-control" maxlength="200" type="text"
                                                               [(ngModel)]="modelDescription.step1B"
                                                               (ngModelChange)="handlerBlockLetters($event,'step1B')"
                                                               appOnlyLetters
                                                               required/>
                                                        <label>{{'modal.step2.label3' | translate}}</label>
                                                    </div>
                                                    <div class="input-material align-items-center d-flex"
                                                         *ngIf="!isNullOrUndefined(modelDescription.step1B)">
                                                        <div class="w-100">
                                                            <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                                <input type="radio" class="custom-control-input"
                                                                       name="stepDescriptionConcentration2BRadio"
                                                                       (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1Blbl')"
                                                                       [value]="'%'"
                                                                       [ngModel]="modelDescription.step1Blbl"
                                                                />
                                                                <label class="custom-control-label">%</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                                <input type="radio" class="custom-control-input"
                                                                       name="stepDescriptionConcentration2BRadio"
                                                                       (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1Blbl')"
                                                                       [value]="'MG'"
                                                                       [ngModel]="modelDescription.step1Blbl"
                                                                />
                                                                <label class="custom-control-label">MG</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                                <input type="radio" class="custom-control-input"
                                                                       name="stepDescriptionConcentration2BRadio"
                                                                       (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1Blbl')"
                                                                       [value]="'MCG'"
                                                                       [ngModel]="modelDescription.step1Blbl"
                                                                />
                                                                <label class="custom-control-label">MCG</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                                <input type="radio" class="custom-control-input"
                                                                       name="stepDescriptionConcentration2BRadio"
                                                                       (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1Blbl')"
                                                                       [value]="'G'"
                                                                       [(ngModel)]="modelDescription.step1Blbl"
                                                                />
                                                                <label class="custom-control-label">G</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                                <input type="radio" class="custom-control-input"
                                                                       name="stepDescriptionConcentration2BRadio"
                                                                       (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1Blbl')"
                                                                       [value]="'ML'"
                                                                       [(ngModel)]="modelDescription.step1Blbl"
                                                                />
                                                                <label class="custom-control-label">ML</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                                <input type="radio" class="custom-control-input"
                                                                       name="stepDescriptionConcentration2BRadio"
                                                                       (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1Blbl')"
                                                                       [value]="'L'"
                                                                       [(ngModel)]="modelDescription.step1Blbl"
                                                                />
                                                                <label class="custom-control-label">L</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                                <input type="radio" class="custom-control-input"
                                                                       name="stepDescriptionConcentration2BRadio"
                                                                       (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1Blbl')"
                                                                       [value]="'UI'"
                                                                       [(ngModel)]="modelDescription.step1Blbl"
                                                                />
                                                                <label class="custom-control-label">UI</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                                <input type="radio" class="custom-control-input"
                                                                       name="stepDescriptionConcentration2BRadio"
                                                                       (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1Blbl')"
                                                                       [value]="'MG/ML'"
                                                                       [(ngModel)]="modelDescription.step1Blbl"
                                                                />
                                                                <label class="custom-control-label">MG/ML</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <!--request-three-->
                                <div class="tab-pane fade" id="step3-modal" role="tabpanel"
                                     aria-labelledby="step3-tab-modal">
                                    <div class="row">
                                        <!--<div class="col-12 mt-5 mb-4">
                                            <h3 class="subTitle">{{'modal.step3.title' | translate}}</h3>
                                        </div>-->
                                        <div class="col-md-12 col-lg-6 offset-lg-3">
                                            <div class="bg-beige p-2 px-1 px-xs-5 rounded">
                                                <div class="register__checkbox">
                                                    <div class="custom-control custom-switch custom-switch-single">
                                                        <input type="checkbox" id="terms2" class="custom-control-input"
                                                               [(ngModel)]="modelDescription.step2"/>
                                                        <label for="terms2"
                                                               class="custom-control-label">{{'modal.step3.text' | translate}}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--request-four-->
                                <div class="tab-pane fade" id="step4-modal" role="tabpanel"
                                     aria-labelledby="step4-tab-modal">
                                    <div class="row">
                                        <!--<div class="col-12 mt-5 mb-4">
                                            <h3 class="subTitle">{{'modal.step4.title' | translate}}</h3>
                                        </div>-->
                                        <div class="col-md-12 col-lg-6">
                                            <p>{{'modal.step4.text' | translate}}</p>
                                            <p><b>{{'modal.step4.exemplo' | translate}}</b></p>
                                            <p>{{'modal.step4.text2' | translate}}</p>
                                            <p>{{'modal.step4.text3' | translate}}</p>
                                        </div>
                                        <div class="col-md-12 col-lg-6">
                                            <div class="bg-beige p-2 px-1 px-xs-5 rounded">
                                                <div class="input-material my-5">
                                                    <input class="form-control" maxlength="200" type="number"
                                                           [(ngModel)]="modelDescription.step3"
                                                           required/>
                                                    <label>{{ 'modal.step4.label' | translate }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--request-five-->
                                <div class="tab-pane fade" id="step5-modal" role="tabpanel"
                                     aria-labelledby="step5-tab-modal">
                                    <div class="row justify-content-center">
                                        <!--<div class="col-12 mt-5 mb-4">
                                            <h3 class="subTitle">{{'modal.step5.title' | translate}}</h3>
                                        </div>-->
                                        <div class="col-md-12 col-lg-4 d-flex align-items-center">
                                            <p>{{'modal.step5.text' | translate}}</p>
                                        </div>
                                        <div class="col-md-12 col-lg-6">
                                            <div class="bg-beige p-2 px-1 px-xs-5 rounded">
                                                <div class="input-material my-5">
                                                    <select class="form-control" (change)="handlerListIsBulk()"
                                                            [(ngModel)]="modelDescription.step4">
                                                        <option value="">Selecione</option>
                                                        <option *ngFor="let p of listPresentation"
                                                                [ngValue]="p.name">{{p.description}}</option>
                                                    </select>
                                                    <label>{{'modal.step5.title' | translate}}</label>
                                                </div>
                                                <div class="register__checkbox"
                                                     *ngIf="modelDescription.step4 === 'CAP' || modelDescription.step4 === 'CPR'">
                                                    <div class="custom-control custom-switch custom-switch-single">
                                                        <input type="checkbox" id="step4isBulk"
                                                               class="custom-control-input"
                                                               (change)="bulkStateToogle()"
                                                               [checked]="modelDescription.step4isBulk"
                                                               [(ngModel)]="modelDescription.step4isBulk"
                                                        />
                                                        <label for="step4isBulk"
                                                               class="custom-control-label">{{'general.bulk' | translate}}</label>
                                                    </div>
                                                </div>
                                                <!--<div class="register__checkbox mt-5">
                                                    <div class="custom-control custom-switch custom-switch-single">
                                                        <input type="checkbox" id="step5isSpace"
                                                               class="custom-control-input"
                                                               [(ngModel)]="modelDescription.step5isSpace"
                                                        />
                                                        <label for="step5isSpace"
                                                               class="custom-control-label">{{'general.spacing' | translate}}</label>
                                                    </div>
                                                </div>-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--request-sixe-->
                                <div class="tab-pane fade" id="step6-modal" role="tabpanel"
                                     aria-labelledby="step6-tab-modal">
                                    <div class="row justify-content-center">
                                        <!--<div class="col-12 mt-5 mb-4">
                                            <h3 class="subTitle">{{'modal.step6.title' | translate}}</h3>
                                        </div>-->
                                        <div class="col-md-12 col-lg-4 d-flex align-items-center">
                                            <p>{{'modal.step6.text' | translate}}</p>
                                        </div>
                                        <div class="col-md-12 col-lg-6">
                                            <div class="bg-beige p-2 px-1 px-xs-5 rounded">
                                                <div class="input-material my-5">
                                                    <input class="form-control" maxlength="200" type="text"
                                                           [(ngModel)]="modelDescription.step5"
                                                           required/>
                                                    <label>{{'modal.step6.title' | translate}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--request-seven-->
                                <div class="tab-pane fade" id="step7-modal" role="tabpanel"
                                     aria-labelledby="step7-tab-modal">
                                    <div class="row">
                                        <!--<div class="col-12 mt-5 mb-4">
                                            <h3 class="subTitle">{{'modal.step7.title' | translate}}</h3>
                                        </div>-->
                                        <div class="col-md-12 col-lg-6 offset-lg-3">
                                            <div class="bg-beige p-2 px-1 px-xs-5 rounded">
                                                <div class="register__checkbox">
                                                    <div class="custom-control custom-switch">
                                                        <input type="checkbox" id="muestra" class="custom-control-input"
                                                               [(ngModel)]="modelDescription.step6"
                                                        />
                                                        <label for="muestra"
                                                               class="custom-control-label">{{'modal.step7.muestra' | translate}}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--request-eight-->
                                <div class="tab-pane fade" id="step8-modal" role="tabpanel"
                                     aria-labelledby="step8-tab-modal">
                                    <div class="row">
                                        <!--<div class="col-12 mt-5 mb-4">
                                            <h3 class="subTitle">{{'modal.step8.title' | translate}}</h3>
                                        </div>-->
                                        <div class="col-12">
                                            <p>{{'modal.step8.text' | translate}}</p>
                                        </div>
                                        <div class="col-12">
                                            <div class="bg-beige p-2 px-1 px-xs-5 rounded">
                                                <div class="assign-team my-4">
                                                    <div>
                                                        <a class="d-inline-flex align-items-center justify-content-center flex-column m-3"
                                                           *ngFor="let n of modelDescriptionCountry"
                                                           href="javascript:void('');"
                                                           (click)="handlerSelectedCountryDescription(n.name, n.description)"
                                                           [ngClass]="{'active' : n.name === modelDescription.step7 || (n.name === 'CHI' && modelDescription.step7 === 'CH')}">
                                                            <!--<svg-icon src="assets/images/country/{{n.thumb}}"></svg-icon>-->
                                                            <img src="assets/images/country/{{n.thumb}}" alt="" />
                                                            <span>{{n.name === 'CHI' ? 'CH' : n.name}}</span>
                                                        </a>
                                                        <!--<a href="javascript:void('');" (click)="modelDescription.step7 = 'ARG'">
                                                            <img class="rounded-circle avatar-sm" alt="64x64"
                                                                src="assets/images/">
                                                        </a>-->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mx-auto mt-1 mt-xs-5 d-flex justify-content-around w-75">
                        <!--<a href="javascript:$('#modalDescriptionForm').modal('hide')"
                           class="btn btn-warning mx-2"
                           type="button">Fechar</a>-->

                        <a href="javascript:void('');" *ngIf="modalDescriptionStep > 0"
                           (click)="prevStepModalDescription()"
                           class="btn btn-outline-primary px-1 px-xs-5"
                           type="button">{{'back' | translate}}</a>

                        <a href="javascript:void('');" *ngIf="modalDescriptionStep < 7"
                           (click)="nextStepModalDescription()"
                           class="btn btn-warning px-1 px-xs-5"
                           type="button">Próximo</a>

                        <a href="javascript:void('');" *ngIf="modalDescriptionStep === 7"
                           (click)="finishStepModalDescription()"
                           class="btn btn-warning px-1 px-xs-5"
                           type="button">Finalizar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Modal BACK STEP -->
<div class="modal-custom modal fade" id="modalBackStep" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalRegisterJustifyLabel"
     aria-hidden="true"
>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <a href="javascript:void('Fechar');" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <div class="row flex-column">
                    <div class="text-center mx-auto font-weight-bold text-primary mt-2 mb-5 size-lg">
                        {{'general.resetRequestText' | translate}}
                    </div>
                    <div class="bg-beige rounded pt-3 pb-1 px-4">
                        <div class="input-material mb-1">
                            <select class="form-control" [(ngModel)]="modelResetStep.step">
                                <option [ngValue]="undefined">Selecione</option>
                                <ng-container *ngFor="let x of [1,2,3,4,5];">
                                    <option *ngIf="x < modelRequest.step" [ngValue]="x - 1">{{x}}
                                        - {{getNameStepByNumber(x)}}</option>
                                </ng-container>
                            </select>
                            <label class="mb-0 mr-3">Etapa</label>
                        </div>
                    </div>
                    <div class="py-3 bg-beige rounded pb-5 px-4">
                        <div class="text-left mb-2">
                            <span class="text-secondary">{{'novocadastro2.descritivo' | translate}}</span>
                        </div>
                        <textarea class="input-material mb-0" id="description44"
                                  [(ngModel)]="modelResetStep.motive"
                                  style="width: 100%; resize: none"
                                  rows="7"
                                  maxlength="900" required></textarea>
                        <label class=" w-100 text-right" for="description44"
                        >
                            {{'novocadastro.maximo1' | translate}}
                            {{handlerMaximumCharaters(modelResetStep.motive)}}
                            {{'novocadastro.maximo2' | translate}}</label>
                    </div>
                    <a href="javascript:void('');" (click)="actionResetStep()"
                       class="btn btn-block btn-primary mt-5">Enviar</a>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Modal Historic Audits -->
<div class="modal-custom modal fade" id="modalHistoryDetail" data-keyboard="false" data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="font-weight-bold text-primary">Histórico</h3>
                <a href="javascript:void('Fechar');" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <div class="row flex-column">
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table table-custom table-striped" border="0" cellpadding="0" cellspacing="0">
                                <tr>
                                    <th>{{'history.date' | translate}}</th>
                                    <th>{{'history.responsible' | translate}}</th>
                                    <th>{{'history.reprobate' | translate}}</th>
                                    <th>{{'history.reason' | translate}}</th>
                                    <th>{{'history.realize' | translate}}</th>
                                </tr>
                                <tr *ngFor="let x of modelRequest.historyAudits">
                                    <td>{{x.createdAt | date: 'dd/MM/yyyy'}}</td>
                                    <td>{{x.auditBy?.name}}</td>
                                    <td>{{x.stepFrom}} - {{getNameStepByNumber(x.stepFrom)}}</td>
                                    <td>{{x.motive}}</td>
                                    <td>{{x.step + 1}} - {{getNameStepByNumber(x.step + 1)}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
