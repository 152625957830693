<div class="d-flex align-items-center ml-4 mt-4" [innerHTML]="'dashboard.title' | translate">
</div>

<div class="dashboard">
    <hr>
    <div class="dashboard_top row">
        <div class="col-12 col-md-12 col-lg-7">
            <font class="dashboard_subtitle">{{'dashboard.toptext' | translate}}</font>
            <div class="input-material" style="max-width: 320px; margin: 0 0 20px">
                <select id="status" class="form-control" name="type" [(ngModel)]="currentYear"
                        (ngModelChange)="onSetFlowType(currentFlowType)">
                    <option *ngFor="let item of years" [value]="item">{{item}}</option>
                </select>
            </div>
        </div>
        <div class="col-12 col-md-12 col-lg-3 offset-lg-2" *ngIf="btnNew">
            <a href="javascript: void(0);" (click)="this.router.navigate(['/register'])"
               class="btn btn-warning btn-home-custom">
              <span class="icon flex-grow-0 mh-100">
                <svg-icon src="assets/svg/plus.svg" class="svg-plus--white--black"></svg-icon>
              </span>
                <span class="separator">|</span>
                <span class="text font-weight-bold primary_font flex-grow-1 btn-mini-text ">{{'dashboard.novasolicitacao' | translate}}</span>
            </a>
        </div>

        <div class="col-12 gap-20">
            <button class="btn btn-sm mr-4" *ngFor="let item of tabFlowType"
                    [ngClass]="{
                        'btn-primary' : item.value === currentFlowType.value,
                        'btn-tertiary' : item.value !== currentFlowType.value
                    }" (click)="onSetFlowType(item)">{{item.text}}</button>
        </div>
    </div>

    <div class="row">

        <div class="col-12 col-xxl-5 col-xl-5">

            <div class="card-primary">
                <div class="card-primary-box">
                    <div class="card-primary-header">
                        <div class="d-flex align-items-center justify-content-between">
                            <p class="card-primary-header-title" [innerHTML]="'numeroProcessoAndamento' | translate"></p>
                        </div>
                        <hr>
                    </div>
                    <div class="card-primary-body">
                        <div class="card-primary-body-graph">
                            <apx-chart
                                    [series]="chartOptionsQuaternary.series"
                                    [chart]="chartOptionsQuaternary.chart"
                                    [dataLabels]="chartOptionsQuaternary.dataLabels"
                                    [plotOptions]="chartOptionsQuaternary.plotOptions"
                                    [yaxis]="chartOptionsQuaternary.yAxis"
                                    [legend]="chartOptionsQuaternary.legend"
                                    [fill]="chartOptionsQuaternary.fill"
                                    [stroke]="chartOptionsQuaternary.stroke"
                                    [tooltip]="chartOptionsQuaternary.tooltip"
                                    [xaxis]="chartOptionsQuaternary.xAxis"
                            ></apx-chart>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-primary" *ngIf="false">
                <div class="card-primary-box">
                    <div class="card-primary-header">
                        <div class="d-flex align-items-center justify-content-between">
                            <p class="card-primary-header-title">{{'dashboard.card1.title' | translate}}
                                <strong> {{'dashboard.card1.etapa' | translate}}</strong></p>
                            <a href="javascript:void('')" class="d-block question_beside_right"
                               data-toggle="popover" *ngIf="false"
                               [attr.title]="getTranslateInstantKey('popovers.dashboard.pop1.title')"
                               [attr.data-content]="getTranslateInstantKey('popovers.dashboard.pop1.text')">
                                <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                            </a>
                        </div>
<!--                    <a href="javascript:void('');" (click)="actionUpdateGraph()">atualizar {{flagResultMoc}}</a>-->
                        <hr>
                    </div>
                    <div class="card-primary-body">
                        <div class="card-primary-body-graph">
                            <apx-chart
                                    [series]="chartOptions.series"
                                    [chart]="chartOptions.chart"
                                    [plotOptions]="chartOptions.plotOptions"
                                    [labels]="chartOptions.labels"
                                    [legend]="chartOptions.legend"
                                    [colors]="chartOptions.colors"
                                    [responsive]="chartOptions.responsive"
                            ></apx-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-xxl-7 col-xl-7">
            <div class="card-primary card-primary-beige">
                <div class="card-primary-box box_2">
                    <div class="card-primary-header">
                        <div class="d-flex align-items-center justify-content-between">
                            <p class="card-primary-header-title">{{'dashboard.card2.title' | translate}}</p>
                            <a href="javascript:void('')" class="d-block question_beside_right"
                               data-toggle="popover" *ngIf="false"
                               [attr.title]="getTranslateInstantKey('popovers.dashboard.pop2.title')"
                               [attr.data-content]="getTranslateInstantKey('popovers.dashboard.pop2.text')">
                                <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                            </a>
                        </div>
                        <hr>
                    </div>
                    <div class="card_primary_rolable">
                        <div class="card-primary-body" *ngFor="let r of modelDashboard.latest">
                            <div class="card-primary-body-graph">
                                <div class="dashboard_open_card">
                                    <div class="row mx-0" (click)="onDetailRequester(r.id)">
                                        <div class="col-12 col-md-4">
                                            <div class="ml-3 mt-3">
                                                <span class="dash_card_title font-weight-bold">{{r.name}}</span>
                                            </div>
                                            <div class="ml-3 mb-3">
                                                <!--<span>{{'dashboard.card2.card.codigo' | translate}}: {{r.code}}</span>-->
                                                <span class="d-flex align-items-center">
                                                    <svg-icon class="svg svg-xs mr-2"
                                                              [ngClass]="{'svg-danger' : r.status == 'disapprove', 'svg-success' : r.status == 'approve'}"
                                                              [src]="'assets/svg/' + getIconByStatus(r.status) + '.svg'"></svg-icon>
                                                                            {{'general.codigomodelo' | translate}}: {{r.code}}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-3 request_steps mt-3">
                                            <div>
                                                <div class="d-flex justify-content-center">
                                                    <span class="unificado mb-1" *ngIf="r.type == 'unification'">
                                                        <svg-icon src="assets/svg/unified.svg"></svg-icon>
                                                        {{'dashboard.card2.card.unificado' | translate}}
                                                    </span>
                                                    <span class="unificado_simples mb-1" *ngIf="r.type == 'simple'">
                                                        <svg-icon src="assets/svg/simple.svg"></svg-icon>
                                                        {{'dashboard.card2.card.simples' | translate}}
                                                    </span>
                                                </div>
                                                <app-country-flags [list]="r.countryNotifications" [listAdd]="r.countryAdditional" [listAddGroup]="r.group"></app-country-flags>
                                                <!--<div class="flags justify-content-center">
                                                    <div class="flags justify-content-center">
                                                        <img class="flags_thumb" *ngIf="r.type == 'simple'"
                                                             src="assets/images/country/{{handlerFlagImage(r.country)}}"
                                                             alt="country"/>
                                                        <ng-container *ngIf="r.type != 'simple'">
                                                            <div *ngFor="let g of r.group; let i = index">
                                                                <img class="flags_thumb" *ngIf="i < 3"
                                                                     src="assets/images/country/{{handlerFlagImage(g.country)}}"
                                                                     alt="country"/>
                                                            </div>
                                                            <span class="p-0 m-0"
                                                                  *ngIf="r.group?.length > 3">+{{r.group?.length - 3}}</span>
                                                        </ng-container>


                                                        <div class="flag_extended" *ngIf="r.group.length > 3">
                                                            <ul>
                                                                <li>
                                                                    <div class="flag_extended_inside">
                                                                        <div class="flag_extended_inside_flag">
                                                                            <img class="flags_thumb"
                                                                                 *ngFor="let g of r.group"
                                                                                 src="assets/images/country/{{handlerFlagImage(g.country)}}"
                                                                                 alt="country"/>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>-->
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-5 request_steps mt-3">
                                            <div class="d-flex justify-content-between">
                                                <span class="position-relative" *ngFor="let i of stepsArray"
                                                      [ngClass]="{'font-weight-bold':r.step == i}">
                                                    {{i}}
                                                    <span style="left: 0;top: 22px;"
                                                            class="position-absolute font-weight-normal">{{r.history[i - 1]?.createdAt | date: 'dd/MM'}}</span>
                                                    <svg-icon *ngIf="!r.history[i - 1]?.createdAt" class="position-absolute" style="left: 0;top: 22px;" src="assets/svg/user.svg"></svg-icon>
                                                </span>


                                            </div>
                                            <div class="progress request_steps_barfull">
                                                <div class="progress-bar request_steps_bar"
                                                     role="progressbar"
                                                     style="{{handlerStepBar(r.step)}}" aria-valuenow="15"
                                                     aria-valuemin="0" aria-valuemax="200">
                                                </div>
                                            </div>
                                            <div>
                                                <span class="mr-5" *ngFor="let h of r.history"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="row">

        <div class="col-12">

            <div class="card-primary">
                <div class="card-primary-box">
                    <div class="card-primary-header">
                        <div class="d-flex align-items-center justify-content-between">
                            <p class="card-primary-header-title" [innerHTML]="'tempoMedioDiasProcesso' | translate"></p>
                        </div>
                        <hr>
                    </div>
                    <div class="card-primary-body">
                        <div class="card-primary-body-graph">
                            <apx-chart
                                    [series]="chartOptionsQuinary.series"
                                    [chart]="chartOptionsQuinary.chart"
                                    [dataLabels]="chartOptionsQuinary.dataLabels"
                                    [plotOptions]="chartOptionsQuinary.plotOptions"
                                    [yaxis]="chartOptionsQuinary.yAxis"
                                    [legend]="chartOptionsQuinary.legend"
                                    [fill]="chartOptionsQuinary.fill"
                                    [stroke]="chartOptionsQuinary.stroke"
                                    [tooltip]="chartOptionsQuinary.tooltip"
                                    [xaxis]="chartOptionsQuinary.xAxis"
                            ></apx-chart>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-primary" *ngIf="false">
                <div class="card-primary-box">
                    <div class="card-primary-header">
                        <div class="d-flex align-items-center justify-content-between">
                            <p class="card-primary-header-title">{{'dashboard.card3.title' | translate}}
                                <strong>{{'dashboard.card3.etapa' | translate}}</strong></p>
                            <a href="javascript:void('')" class="d-block question_beside_right"
                               data-toggle="popover" *ngIf="false"
                               [attr.title]="getTranslateInstantKey('popovers.dashboard.pop3.title')"
                               [attr.data-content]="getTranslateInstantKey('popovers.dashboard.pop3.text')">
                                <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                            </a>
                        </div>
                        <hr>
                    </div>
                    <div class="card-primary-body">
                        <div class="card-primary-body-graph">
                            <apx-chart
                                    [series]="chartOptionsTertiary.series"
                                    [chart]="chartOptionsTertiary.chart"
                                    [dataLabels]="chartOptionsTertiary.dataLabels"
                                    [plotOptions]="chartOptionsTertiary.plotOptions"
                                    [yaxis]="chartOptionsTertiary.yAxis"
                                    [legend]="chartOptionsTertiary.legend"
                                    [fill]="chartOptionsTertiary.fill"
                                    [stroke]="chartOptionsTertiary.stroke"
                                    [tooltip]="chartOptionsTertiary.tooltip"
                                    [xaxis]="chartOptionsTertiary.xAxis"
                            ></apx-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-xl-6" *ngIf="false">
            <div class="card-primary">
                <div class="card-primary-box">
                    <div class="card-primary-header">
                        <div class="d-flex align-items-center justify-content-between">
                            <p class="card-primary-header-title">{{'dashboard.card4.title' | translate}}</p>
                            <a href="javascript:void('')" class="d-block question_beside_right"
                               data-toggle="popover" *ngIf="false"
                               [attr.title]="getTranslateInstantKey('popovers.dashboard.pop4.title')"
                               [attr.data-content]="getTranslateInstantKey('popovers.dashboard.pop4.text')">
                                <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                            </a>
                        </div>
                        <hr>
                    </div>
                    <div class="card-primary-body">
                        <div class="card-primary-body-graph">
                            <apx-chart
                                    [series]="chartOptionsSecondary.series"
                                    [chart]="chartOptionsSecondary.chart"
                                    [xaxis]="chartOptionsSecondary.xAxis"
                                    [stroke]="chartOptionsSecondary.stroke"
                                    [tooltip]="chartOptionsSecondary.tooltip"
                                    [dataLabels]="chartOptionsSecondary.dataLabels"
                                    [legend]="chartOptionsSecondary.legend"
                                    [markers]="chartOptionsSecondary.markers"
                                    [grid]="chartOptionsSecondary.grid"
                                    [yaxis]="chartOptionsSecondary.yAxis"
                                    [title]="chartOptionsSecondary.title"
                            ></apx-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>




